import IIconProp from '../../../../iconInterface';

const SPlusIcon: React.FC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg viewBox="0 0 800 800" className={className}>
    <g fill={fillColor}>
      <path d="m399.3 221.25c-15.76 14.45-25.45 23.8-29.06 28.06-22.32 29.23-18.88 56.3 10.34 81.25 18.05-20.34 41.85-37.74 71.4-52.19 36.75-17.39 68.6-24.78 95.51-22.15 1.97-32.82-6.89-55.64-26.58-68.45-18.72-11.81-41.36-11.48-67.94.98-1.65-4.92-3.62-9.67-5.92-14.28-10.83-19.69-25.93-30.84-45.29-33.47-56.13-7.56-110.13 6.73-161.99 42.83-39.39 27.58-71.56 62.53-96.5 104.88-19.7 38.41-20.69 74.35-2.97 107.83 4.6 8.21 10.35 16.08 17.24 23.63 13.45 15.11 35.6 31.2 66.47 48.25 8.86 4.92 15.09 8.54 18.71 10.83 12.8 8.21 20.35 15.6 22.64 22.16 1.98 10.18-3.61 22.82-16.74 37.9-5.91 6.24-12.8 12.81-20.68 19.7-28.23 24.29-52.52 39.22-72.87 44.8-17.4 4.27-25.28-1.48-23.64-17.23 2.3-24.28 11.49-44.64 27.58-61.05 14.1-14.1 27.4-20.18 39.87-18.21-.32-.98-.81-1.97-1.48-2.95-7.56-14.45-22.48-26.1-44.8-34.96-26.93-10.83-48.25-10.83-64.01 0-19.04 31.52-26.91 59.58-23.64 84.2 2.63 20.35 15.59 43.32 38.9 68.93 9.18 10.18 19.12 18.37 29.79 24.61 10.66 6.23 21.25 10.26 31.76 12.07 10.5 1.8 21.25 2.21 32.25 1.22 10.99-.98 21.58-3.45 31.76-7.38 10.17-3.94 20.1-8.78 29.79-14.52 9.67-5.75 18.79-12.32 27.33-19.7 8.53-7.38 16.32-14.93 23.39-22.64 7.05-7.72 13.21-15.67 18.47-23.88 15.42-26.91 19.69-55.47 12.8-85.67-5.25-22.32-17.4-42.35-36.44-60.07-7.22-6.57-22.98-18.05-47.27-34.47-16.42-10.83-27.07-20.34-32-28.55-5.91-9.86-6.24-22.66-.98-38.41 7.21-19.69 32.82-46.94 76.8-81.74 12.8-10.17 26.1-19.2 39.88-27.07 30.19-17.72 52.84-25.93 67.94-24.61 5.91.65 8.37 3.27 7.38 7.88-1.62 5.24-7.35 12.46-17.2 21.64z" />

      <path d="m725.48 368.75h-85.42v-85.42c0-5.66-4.59-10.26-10.26-10.26h-49.31c-5.66 0-10.26 4.59-10.26 10.26v85.42h-85.42c-5.66 0-10.26 4.59-10.26 10.26v49.3c0 5.67 4.59 10.26 10.26 10.26h85.42v85.42c0 5.67 4.59 10.26 10.26 10.26h49.31c5.66 0 10.26-4.59 10.26-10.26v-85.42h85.42c5.67 0 10.26-4.59 10.26-10.26v-49.31c-.01-5.66-4.6-10.25-10.26-10.25z" />
    </g>
  </svg>
);

export { SPlusIcon };
