import React from 'react';

export function StockLevels({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 30 28" className={className}>
      <title>Stock levels</title>
      <defs>
        <path
          d="M29.167 15H25v5l-1.667-1.11L21.667 20v-5H17.5a.836.836 0 00-.833.833v10c0 .459.375.834.833.834h11.667a.836.836 0 00.833-.834v-10a.836.836 0 00-.833-.833zm-20-3.333h11.666a.836.836 0 00.834-.834v-10A.836.836 0 0020.833 0h-4.166v5L15 3.89 13.333 5V0H9.167a.836.836 0 00-.834.833v10c0 .459.375.834.834.834zM12.5 15H8.333v5l-1.666-1.11L5 20v-5H.833a.836.836 0 00-.833.833v10c0 .459.375.834.833.834H12.5a.836.836 0 00.833-.834v-10A.836.836 0 0012.5 15z"
          id="sl_prefix__a"
        />
      </defs>
      <g transform="translate(-981 -146)" fill="none" fillRule="evenodd">
        <g transform="translate(981 146.5)">
          <mask id="sl_prefix__b" fill="#fff">
            <use xlinkHref="#sl_prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#sl_prefix__a" />
          <g mask="url(#sl_prefix__b)" fill={fillColor}>
            <path d="M-18-19.5h66v66h-66z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
