import React from 'react';

export function ZeroStock({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 30 30" className={className}>
      <defs>
        <path
          d="M30 15c0 8.286-6.716 15-15 15-8.284 0-15-6.714-15-15C0 6.719 6.716 0 15 0c8.284 0 15 6.719 15 15zm-15 3.024a2.782 2.782 0 100 5.565 2.782 2.782 0 000-5.565zm-2.642-10l.45 8.225c.02.385.338.686.724.686h2.936a.726.726 0 00.725-.686l.449-8.226a.726.726 0 00-.725-.765h-3.834a.726.726 0 00-.725.765z"
          id="zs_prefix__a"
        />
      </defs>
      <g transform="translate(-1141 -145)" fill="none" fillRule="evenodd">
        <g transform="translate(1141 145)">
          <mask id="zs_prefix__b" fill="#fff">
            <use xlinkHref="#zs_prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#zs_prefix__a" />
          <g mask="url(#zs_prefix__b)" fill={fillColor}>
            <path d="M-18-18h66v66h-66z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
