// *******************************************************
// NotepadBudgetIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const NotepadBudgetIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp) => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill={fillColor}
      className={className}
    >
      <path d="m305.951 49.951c0-27.588-22.363-49.951-49.951-49.951s-49.951 22.363-49.951 49.951h-49.951v49.951h199.805v-49.951z" />
      <path d="m293.465 343.415h-18.733v37.463h18.733c10.329 0 18.732-8.403 18.732-18.732-.001-10.328-8.402-18.731-18.732-18.731z" />
      <path d="m218.538 268.488c-10.329 0-18.732 8.403-18.732 18.732s8.403 18.732 18.732 18.732h18.73v-37.463h-18.73z" />
      <path d="m393.366 99.902v37.463h-274.732v-37.463h-49.951v412.098h374.634v-412.098zm-99.901 318.439h-18.733v31.219h-37.463v-31.219h-18.73c-14.987 0-29.692-2.513-43.709-7.468l12.487-35.32c9.995 3.534 20.5 5.325 31.222 5.325h18.73v-37.463h-18.73c-30.986 0-56.195-25.209-56.195-56.195s25.209-56.195 56.195-56.195h18.73v-31.22h37.463v31.22h18.733c14.987 0 29.691 2.513 43.709 7.468l-12.488 35.321c-9.995-3.534-20.499-5.325-31.221-5.325h-18.733v37.463h18.733c30.987 0 56.195 25.209 56.195 56.195 0 30.985-25.208 56.194-56.195 56.194z" />
    </svg>
  );
};

export default NotepadBudgetIcon;
