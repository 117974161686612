import * as React from 'react';
import IIconProp from '../iconInterface';

const MarketLogoIcon: React.VFC<IIconProp> = ({
  fillColor = '#000000',
  className = 'h-full w-auto',
}) => (
  <svg
    className={className}
    viewBox="0 0 59 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.8333 17.3334V21.5001H58.3333V56.9167C58.3333 62.6697 53.6697 67.3334 47.9167 67.3334H10.4167C4.66367 67.3334 0 62.6697 0 56.9167V21.5001H12.5V17.3334C12.5 8.14331 19.9766 0.666748 29.1667 0.666748C38.3566 0.666748 45.8333 8.14331 45.8333 17.3334ZM29.1667 9.00008C24.5716 9.00008 20.8333 12.7384 20.8333 17.3334V21.5001H37.5V17.3334C37.5 12.7384 33.7617 9.00008 29.1667 9.00008ZM41.6667 32.9584C39.9408 32.9584 38.5417 31.5593 38.5417 29.8334C38.5417 28.1075 39.9408 26.7084 41.6667 26.7084C43.3926 26.7084 44.7917 28.1075 44.7917 29.8334C44.7917 31.5593 43.3926 32.9584 41.6667 32.9584ZM13.5417 29.8334C13.5417 31.5593 14.9408 32.9584 16.6667 32.9584C18.3926 32.9584 19.7917 31.5593 19.7917 29.8334C19.7917 28.1075 18.3926 26.7084 16.6667 26.7084C14.9408 26.7084 13.5417 28.1075 13.5417 29.8334Z"
      fill={fillColor}
    />
    <mask
      id="mask0_0_248"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="59"
      height="68"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8333 17.3334V21.5001H58.3333V56.9167C58.3333 62.6697 53.6697 67.3334 47.9167 67.3334H10.4167C4.66367 67.3334 0 62.6697 0 56.9167V21.5001H12.5V17.3334C12.5 8.14331 19.9766 0.666748 29.1667 0.666748C38.3566 0.666748 45.8333 8.14331 45.8333 17.3334ZM29.1667 9.00008C24.5716 9.00008 20.8333 12.7384 20.8333 17.3334V21.5001H37.5V17.3334C37.5 12.7384 33.7617 9.00008 29.1667 9.00008ZM41.6667 32.9584C39.9408 32.9584 38.5417 31.5593 38.5417 29.8334C38.5417 28.1075 39.9408 26.7084 41.6667 26.7084C43.3926 26.7084 44.7917 28.1075 44.7917 29.8334C44.7917 31.5593 43.3926 32.9584 41.6667 32.9584ZM13.5417 29.8334C13.5417 31.5593 14.9408 32.9584 16.6667 32.9584C18.3926 32.9584 19.7917 31.5593 19.7917 29.8334C19.7917 28.1075 18.3926 26.7084 16.6667 26.7084C14.9408 26.7084 13.5417 28.1075 13.5417 29.8334Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_248)">
      <rect
        x="-43.3333"
        y="-39.3333"
        width="146.667"
        height="146.667"
        fill={fillColor}
      />
    </g>
  </svg>
);

export { MarketLogoIcon };
