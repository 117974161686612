// *******************************************************
// TapestryLogoIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../iconInterface';
export interface ITapestryLogoIconProps extends IIconProp {
  width?: string;
  height?: string;
  inWhite?: boolean;
}

// *******************************************
// Main Component
// -------------------------------------------
export const TapestryLogoIcon = ({
  width = '60px',
  height = '60px',
  className,
  inWhite,
}: ITapestryLogoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 60 60"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="WEB-HD-|-account-session-create"
          transform="translate(-690.000000, -165.000000)"
        >
          <g id="Group-5" transform="translate(420.000000, 100.000000)">
            <g id="Group-4" transform="translate(110.000000, 65.000000)">
              <g id="Group-2" transform="translate(107.000000, 0.000000)">
                <g id="Group" transform="translate(53.000000, 0.000000)">
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#ffffff0' : '#fff'}
                    transform="translate(29.959609, 29.910002) rotate(45.000000) translate(-29.959609, -29.910002) "
                    points="18.5382287 18.4886213 41.3809893 18.4886213 41.3809893 41.331382 18.5382287 41.331382"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#D20F3B'}
                    transform="translate(29.959609, 10.414808) rotate(45.000000) translate(-29.959609, -10.414808) "
                    points="22.979323 3.43452229 36.939895 3.43452229 36.939895 17.3950942 22.979323 17.3950942"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#0795A0'}
                    transform="translate(29.959609, 49.504410) rotate(45.000000) translate(-29.959609, -49.504410) "
                    points="22.979323 42.5241238 36.939895 42.5241238 36.939895 56.4846957 22.979323 56.4846957"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#889AA4'}
                    transform="translate(49.504410, 29.959609) rotate(45.000000) translate(-49.504410, -29.959609) "
                    points="42.5241238 22.979323 56.4846957 22.979323 56.4846957 36.939895 42.5241238 36.939895"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#5A3B8F'}
                    transform="translate(10.414808, 29.959609) rotate(45.000000) translate(-10.414808, -29.959609) "
                    points="3.43452229 22.979323 17.3950942 22.979323 17.3950942 36.939895 3.43452229 36.939895"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#C33C8E'}
                    transform="translate(20.187209, 20.187209) rotate(45.000000) translate(-20.187209, -20.187209) "
                    points="13.2069227 13.2069227 27.1674946 13.2069227 27.1674946 27.1674946 13.2069227 27.1674946"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#23B0E7'}
                    transform="translate(20.187209, 39.732009) rotate(45.000000) translate(-20.187209, -39.732009) "
                    points="13.2069227 32.7517234 27.1674946 32.7517234 27.1674946 46.7122954 13.2069227 46.7122954"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#F28A14'}
                    transform="translate(39.732009, 20.187209) rotate(45.000000) translate(-39.732009, -20.187209) "
                    points="32.7517234 13.2069227 46.7122954 13.2069227 46.7122954 27.1674946 32.7517234 27.1674946"
                  />
                  <polygon
                    id="Rectangle"
                    fill={inWhite ? '#fff' : '#CAD526'}
                    transform="translate(39.732009, 39.732009) rotate(45.000000) translate(-39.732009, -39.732009) "
                    points="32.7517234 32.7517234 46.7122954 32.7517234 46.7122954 46.7122954 32.7517234 46.7122954"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TapestryLogoIcon;
