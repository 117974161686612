import * as React from 'react';
import IIconProp from '../iconInterface';

const EnvelopeIcon: React.VFC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg className={className} viewBox="0 0 24 24">
    <defs>
      <path
        d="M21.583 5.448A.259.259 0 0 1 22 5.65v8.787a2.063 2.063 0 0 1-2.063 2.063H2.063A2.063 2.063 0 0 1 0 14.437V5.655c0-.215.245-.335.417-.202.962.747 2.238 1.697 6.621 4.881.907.662 2.437 2.054 3.962 2.045 1.534.013 3.094-1.41 3.966-2.045 4.383-3.184 5.655-4.138 6.617-4.886zM11 11c.997.017 2.432-1.255 3.154-1.779 5.702-4.138 6.136-4.499 7.45-5.53.25-.193.396-.494.396-.812v-.817A2.063 2.063 0 0 0 19.937 0H2.063A2.063 2.063 0 0 0 0 2.063v.816c0 .318.146.614.395.812 1.315 1.027 1.75 1.392 7.451 5.53.722.524 2.157 1.796 3.154 1.779z"
        id="497qbrrvja"
      />
    </defs>
    <g transform="translate(1 4)" fill="none" fillRule="evenodd">
      <use fill={fillColor} fillRule="nonzero" xlinkHref="#497qbrrvja" />
    </g>
  </svg>
);

export { EnvelopeIcon };
