import React from 'react';
import IIconProp from '../iconInterface';

export const ShoppingBagIcon: React.VFC<IIconProp> = ({
  className = 'w-auto h-full',
  fillColor,
}) => {
  return (
    <svg
      className={className}
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          fill={fillColor}
          d="M21.7708333,9.89583333 L21.7708333,7.91666667 C21.7708333,3.55136719 18.2194043,0 13.8541667,0 C9.48886719,0 5.9375,3.55136719 5.9375,7.91666667 L5.9375,9.89583333 L0,9.89583333 L0,26.71875 C0,29.4514225 2.21524414,31.6666667 4.94791667,31.6666667 L22.7604167,31.6666667 C25.4930892,31.6666667 27.7083333,29.4514225 27.7083333,26.71875 L27.7083333,9.89583333 L21.7708333,9.89583333 Z M9.89583333,7.91666667 C9.89583333,5.73401693 11.6715169,3.95833333 13.8541667,3.95833333 C16.0368164,3.95833333 17.8125,5.73401693 17.8125,7.91666667 L17.8125,9.89583333 L9.89583333,9.89583333 L9.89583333,7.91666667 Z M19.7916667,15.3385417 C18.9718587,15.3385417 18.3072917,14.6739746 18.3072917,13.8541667 C18.3072917,13.0343587 18.9718587,12.3697917 19.7916667,12.3697917 C20.6114746,12.3697917 21.2760417,13.0343587 21.2760417,13.8541667 C21.2760417,14.6739746 20.6114746,15.3385417 19.7916667,15.3385417 Z M7.91666667,15.3385417 C7.09685872,15.3385417 6.43229167,14.6739746 6.43229167,13.8541667 C6.43229167,13.0343587 7.09685872,12.3697917 7.91666667,12.3697917 C8.73647461,12.3697917 9.40104167,13.0343587 9.40104167,13.8541667 C9.40104167,14.6739746 8.73647461,15.3385417 7.91666667,15.3385417 Z"
          id="ShoppingBagIconpath-1"
        ></path>
      </defs>
      <g
        id="ShoppingBagIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ShoppingBagIconColours/Black-#000000"
          transform="translate(4.750000, 3.166667)"
        >
          <mask id="ShoppingBagIconmask-2" fill="white">
            <use xlinkHref="#ShoppingBagIconpath-1"></use>
          </mask>
          <use
            id="ShoppingBagIconMask"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#ShoppingBagIconpath-1"
          ></use>
          <g id="ShoppingBagIconGroup" mask="url(#ShoppingBagIconmask-2)">
            <g
              transform="translate(-20.583333, -19.000000)"
              id="ShoppingBagIconColours/Black-#000000"
            >
              <rect
                id="ShoppingBagIconRectangle"
                fill={fillColor}
                x="0"
                y="0"
                width="69.6666667"
                height="69.6666667"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShoppingBagIcon;
