import React, { FunctionComponent } from 'react';

export const MainChartLoadingIcon: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      viewBox="0 0 1440 177"
      className="h-full w-auto"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="m0 587.744867 66.3003034-49.616206 52.5271476-1.550506 46.71077-6.202026 60.053297 6.202026 39.490165 7.752532 57.404777-10.853545 51.175854 79.075828 48.694011 26.35861 50.217592-79.075829 53.806292-56.835751 54.30907 80.093348 44.367794-46.515193 48.584266-3.101013 55.118735 7.752532 41.113705 10.853545 24.070473-40.313167 29.894201 75.974815 52.939693-58.919244 49.412014 57.368738 54.922935 18.606077 48.148205 41.863674 55.64094-113.18697 48.69401 26.358609 43.98858-23.257596 52.52724-22.715247 55.5507 73.881959 53.02993-46.515193 47.73575-17.055571 53.57155 38.762661v117.063236h-1440z"
        fill="url(#a)"
        fillRule="evenodd"
        transform="translate(0 -503)"
        stroke="#8884d8"
        strokeWidth="3px"
        // className="primary-chart__loading-chart__main-stroke"
      />
    </svg>
  );
};

// export const MainChartLoadingIcon: FunctionComponent = () => {
//   return (
//     <svg
//       viewBox="0 0 1440 177"
//       className="w-auto h-full"
//       xmlns="http://www.w3.org/2000/svg"
//       xmlnsXlink="http://www.w3.org/1999/xlink"
//     >
//       <path
//         d="m0 587.744867 66.3003034-49.616206 52.5271476-1.550506 46.71077-6.202026 60.053297 6.202026 39.490165 7.752532 57.404777-10.853545 51.175854 79.075828 48.694011 26.35861 50.217592-79.075829 53.806292-56.835751 54.30907 80.093348 44.367794-46.515193 48.584266-3.101013 55.118735 7.752532 41.113705 10.853545 24.070473-40.313167 29.894201 75.974815 52.939693-58.919244 49.412014 57.368738 54.922935 18.606077 48.148205 41.863674 55.64094-113.18697 48.69401 26.358609 43.98858-23.257596 52.52724-22.715247 55.5507 73.881959 53.02993-46.515193 47.73575-17.055571 53.57155 38.762661v117.063236h-1440z"
//         fill="url(#a)"
//         fillRule="evenodd"
//         transform="translate(0 -503)"
//         stroke="#8884d8"
//         strokeWidth="3px"
//         className="primary-chart__loading-chart__main-stroke"
//       />
//     </svg>
//   );
// };

// export const MainChartLoadingIcon: FunctionComponent = () => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1450 160">
//       <path
//         d="M1035.88,160l-52.3-45.58L928.29,95.65,881.7,41.44l-54.55,60.84L798,28.09,777.24,63,732.77,51.21l-54.32-7.66-46.12,3L585,96.19,530.73,16,481.54,68l-52.46,82.78-53.92-29.25-50.05-77.5L270.06,54.5l-40.21-7.92-59.24-6.13-46.38,6.17L73,48.14,8,96.91a5,5,0,0,1-6-8L69.57,38.22l53.85-1.6,47-6.26,61.09,6.35,38.55,7.58L329.86,33l52.31,81,43.47,23.59,48-75.71L532,0l54.32,80.29,41.44-43.54,51-3.27,56.21,8,37.47,9.91L799.87,5.43l30.66,78.09,51.33-57.25L934.09,87l54.56,18.52,44,38.33,55.07-112.26,50.91,27.62,42-22.23,56.14-24.33,54.68,72.88,49.69-43.68,51.12-18.3L1447.93,64a5,5,0,0,1-5.86,8.12l-51.44-37.3-44.36,15.88-56.36,49.55L1233.48,25l-48.73,21.12-46.18,24.47L1092.09,45.4Z"
//         fill="#c4c4c4"
//         stroke="#c4c4c4" // this is ouline or border
//         transform="translate(0)"
//         className="primary-chart__loading-chart__main-stroke"
//       />
//     </svg>
//   );
// };

export default MainChartLoadingIcon;
