// *******************************************************
// IceCreamIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const IceCreamIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Ice cream</title>
      <defs>
        <path
          d="M14,6.68444574 L13.9608333,6.68444574 C14.224097,4.3916839 13.1490108,2.15113624 11.1957876,0.921915532 C9.2425643,-0.307305177 6.7574357,-0.307305177 4.80421244,0.921915532 C2.85098918,2.15113624 1.77590296,4.3916839 2.03916667,6.68444574 L2,6.68444574 C0.8954305,6.68444574 0,7.57987624 0,8.68444574 C0,9.78901524 0.8954305,10.6844457 2,10.6844457 L14,10.6844457 C15.1045695,10.6844457 16,9.78901524 16,8.68444574 C16,7.57987624 15.1045695,6.68444574 14,6.68444574 L14,6.68444574 Z M6.8075,20.5881957 C7.02214708,21.0533681 7.48769278,21.3512671 8,21.3512671 C8.51230722,21.3512671 8.97785292,21.0533681 9.1925,20.5881957 L13.3333333,12.0177791 L2.66666667,12.0177791 L6.8075,20.5881957 Z"
          id="path-1-ice-cream-qeiury"
        ></path>
      </defs>
      <g
        id="00-Micronaires/Icons/Ice-cream"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Colours/Black-#000000-ice-cream-qeiury"
          transform="translate(4.000000, 1.000000)"
        >
          <mask id="mask-2-ice-cream-qeiury" fill="white">
            <use xlinkHref="#path-1-ice-cream-qeiury"></use>
          </mask>
          <use
            id="Mask-ice-cream-qeiury"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#path-1-ice-cream-qeiury"
          ></use>
          <g id="Group-ice-cream-qeiury" mask="url(#mask-2-ice-cream-qeiury)">
            <g transform="translate(-14.000000, -11.000000)">
              <rect
                id="Rectangle-ice-cream-qeiury"
                fill={fillColor}
                x="0"
                y="0"
                className={className}
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IceCreamIcon;
