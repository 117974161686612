import * as React from 'react';
import IIconProp from '../iconInterface';

export const UsersIcon: React.VFC<IIconProp> = ({
  fillColor = '#000',
  className = 'h-full w-auto',
}) => (
  <svg
    className={className}
    viewBox="0 0 38 38"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        fill={fillColor}
        d="M4.75,9.5 C6.49661458,9.5 7.91666667,8.07994792 7.91666667,6.33333333 C7.91666667,4.58671875 6.49661458,3.16666667 4.75,3.16666667 C3.00338542,3.16666667 1.58333333,4.58671875 1.58333333,6.33333333 C1.58333333,8.07994792 3.00338542,9.5 4.75,9.5 Z M26.9166667,9.5 C28.6632812,9.5 30.0833333,8.07994792 30.0833333,6.33333333 C30.0833333,4.58671875 28.6632812,3.16666667 26.9166667,3.16666667 C25.1700521,3.16666667 23.75,4.58671875 23.75,6.33333333 C23.75,8.07994792 25.1700521,9.5 26.9166667,9.5 Z M28.5,11.0833333 L25.3333333,11.0833333 C24.4625,11.0833333 23.6757812,11.4346354 23.1018229,12.0036458 C25.0958333,13.0971354 26.5109375,15.0713542 26.8177083,17.4166667 L30.0833333,17.4166667 C30.9591146,17.4166667 31.6666667,16.7091146 31.6666667,15.8333333 L31.6666667,14.25 C31.6666667,12.5033854 30.2466146,11.0833333 28.5,11.0833333 Z M15.8333333,11.0833333 C18.8960937,11.0833333 21.375,8.60442708 21.375,5.54166667 C21.375,2.47890625 18.8960937,0 15.8333333,0 C12.7705729,0 10.2916667,2.47890625 10.2916667,5.54166667 C10.2916667,8.60442708 12.7705729,11.0833333 15.8333333,11.0833333 Z M19.6333333,12.6666667 L19.2226562,12.6666667 C18.1934896,13.1614583 17.0505208,13.4583333 15.8333333,13.4583333 C14.6161458,13.4583333 13.478125,13.1614583 12.4440104,12.6666667 L12.0333333,12.6666667 C8.88645833,12.6666667 6.33333333,15.2197917 6.33333333,18.3666667 L6.33333333,19.7916667 C6.33333333,21.1028646 7.39713542,22.1666667 8.70833333,22.1666667 L22.9583333,22.1666667 C24.2695312,22.1666667 25.3333333,21.1028646 25.3333333,19.7916667 L25.3333333,18.3666667 C25.3333333,15.2197917 22.7802083,12.6666667 19.6333333,12.6666667 Z M8.56484375,12.0036458 C7.99088542,11.4346354 7.20416667,11.0833333 6.33333333,11.0833333 L3.16666667,11.0833333 C1.42005208,11.0833333 0,12.5033854 0,14.25 L0,15.8333333 C0,16.7091146 0.707552083,17.4166667 1.58333333,17.4166667 L4.84401042,17.4166667 C5.15572917,15.0713542 6.57083333,13.0971354 8.56484375,12.0036458 Z"
        id="UsersIconpath-1"
      ></path>
    </defs>
    <g
      id="UsersIcon"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="UsersIconColours/Black-#000000"
        transform="translate(3.166667, 7.916667)"
      >
        <mask id="UsersIconmask-2" fill="white">
          <use xlinkHref="#UsersIconpath-1"></use>
        </mask>
        <use
          id="UsersIconMask"
          fill={fillColor}
          fillRule="nonzero"
          xlinkHref="#UsersIconpath-1"
        ></use>
        <g id="UsersIconGroup" mask="url(#UsersIconmask-2)">
          <g
            transform="translate(-19.000000, -23.750000)"
            id="UsersIconColours/Black-#000000"
          >
            <rect
              id="UsersIconRectangle"
              fill={fillColor}
              x="0"
              y="0"
              width="69.6666667"
              height="69.6666667"
            ></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UsersIcon;
