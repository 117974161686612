// *******************************************************
// SpacesIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type ISpacesIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const SpacesIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: ISpacesIconProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillColor}
        d="m17.8571429 0c1.1830357 0 2.1428571.95982143 2.1428571 2.14285714v13.89610386c0 1.1830358-.9598214 2.1428572-2.1428571 2.1428572h-2.0779221c-1.1830357 0-2.1428572-.9598214-2.1428572-2.1428572v-13.89610386c0-1.18303571.9598215-2.14285714 2.1428572-2.14285714zm-9.09090913 10.9090909c1.18303571 0 2.14285713.9598214 2.14285713 2.1428572v2.9870129c0 1.1830358-.95982142 2.1428572-2.14285713 2.1428572h-6.62337663c-1.18303571 0-2.14285714-.9598214-2.14285714-2.1428572v-2.9870129c0-1.1830358.95982143-2.1428572 2.14285714-2.1428572zm0-10.9090909c1.18303571 0 2.14285713.95982143 2.14285713 2.14285714v2.98701299c0 1.18303571-.95982142 2.14285714-2.14285713 2.14285714h-6.62337663c-1.18303571 0-2.14285714-.95982143-2.14285714-2.14285714v-2.98701299c0-1.18303571.95982143-2.14285714 2.14285714-2.14285714z"
      />
    </svg>
  );
};

export default SpacesIcon;
