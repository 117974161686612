import IIconProp from '../../../iconInterface';
import * as React from 'react';

// Icon Reference: https://fontawesome.com/icons/circle-location-arrow?s=solid

export const LocationCircleIcon: React.VFC<IIconProp> = ({
  fillColor = '#000',
  className = 'h-full w-auto',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      fill={fillColor}
      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM353.6 158.5C347.3 152.1 337.8 150.3 329.3 153.8L133.3 237.8C123.8 241.9 118.3 252.3 120.4 262.4C122.5 272.6 131.6 280 141.1 280H232V370C232 380.4 239.4 389.5 249.6 391.6C251.1 391.9 252.6 392 253.1 392C262.8 392 270.7 386.8 274.2 378.7L358.2 182.7C361.8 174.3 359.1 164.8 353.6 158.5V158.5z"
    />
  </svg>
);

export default LocationCircleIcon;
