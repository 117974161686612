import React, { FunctionComponent } from 'react';

export const QuestionIcon: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <defs>
        <path
          id="QuestionIcon_a"
          d="M10.682 0C5.849 0 2.719 1.98.262 5.511c-.446.64-.309 1.52.313 1.99l2.612 1.981c.628.476 1.521.365 2.013-.251 1.517-1.9 2.642-2.994 5.01-2.994 1.863 0 4.167 1.199 4.167 3.005 0 1.366-1.127 2.067-2.966 3.098-2.145 1.202-4.983 2.699-4.983 6.442v.593c0 .803.65 1.453 1.453 1.453h4.388c.803 0 1.453-.65 1.453-1.453v-.35c0-2.595 7.585-2.703 7.585-9.725 0-5.288-5.486-9.3-10.625-9.3zm-.607 22.612c-2.312 0-4.194 1.881-4.194 4.194 0 2.313 1.882 4.194 4.194 4.194 2.313 0 4.194-1.881 4.194-4.194 0-2.313-1.881-4.194-4.194-4.194z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(8.15 3.5)">
        <mask id="QuestionIconb" fill="#fff">
          <use xlinkHref="#QuestionIcon_a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#QuestionIcon_a" />
        <g fill="#FFF" mask="url(#QuestionIconb)">
          <path d="M0 0H68.2V68.2H0z" transform="translate(-23.25 -18.6)" />
        </g>
      </g>
    </svg>
  );
};

export default QuestionIcon;
