// *******************************************************
// CalendarDayWeekIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type ICalendarDayWeekIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const CalendarDayWeekIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: ICalendarDayWeekIconProps) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          fill={fillColor}
          id="gyfwvhowoa"
          d="M0 36.25C0 38.32 1.68 40 3.75 40h27.5c2.07 0 3.75-1.68 3.75-3.75V15H0v21.25zm5-15c0-.688.563-1.25 1.25-1.25h22.5c.688 0 1.25.563 1.25 1.25v5c0 .688-.563 1.25-1.25 1.25H6.25c-.688 0-1.25-.563-1.25-1.25v-5zM31.25 5H27.5V1.25C27.5.562 26.937 0 26.25 0h-2.5c-.688 0-1.25.563-1.25 1.25V5h-10V1.25C12.5.562 11.937 0 11.25 0h-2.5C8.062 0 7.5.563 7.5 1.25V5H3.75C1.68 5 0 6.68 0 8.75v3.75h35V8.75C35 6.68 33.32 5 31.25 5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(6 4)">
        <mask id="qlcqo6ho4b" fill={fillColor}>
          <use xlinkHref="#gyfwvhowoa" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#gyfwvhowoa" />
        <g fill={fillColor} mask="url(#qlcqo6ho4b)">
          <path d="M-26-24h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
};

export default CalendarDayWeekIcon;
