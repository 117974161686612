import React, { FunctionComponent } from 'react';
import IIconProp from '../iconInterface';

export const HankHLogoIcon: FunctionComponent<
  React.PropsWithChildren<IIconProp>
> = ({ fillColor = '#000000', className = 'h-full w-auto' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="hank-logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="HankLogoIconGroup" fill={fillColor} fillRule="nonzero">
          <g id="HankLogoIconCombined-Shape">
            <path d="M18.9564104,4.84078998 C18.328762,6.21624997 17.4009001,8.76845351 16.4423168,11.5193735 C15.1450212,15.2782642 14.0534419,18.5308362 13.8395592,20.1676219 C13.2787977,20.211954 11.540515,19.9572392 11.4549619,18.8843259 C11.3616313,17.7134155 11.8539503,15.5637003 12.6565936,12.9967194 C11.5342929,13.0861613 10.4652685,13.2191574 9.47129737,13.3719863 C8.53215802,16.0723522 7.73923664,18.7614406 7.41335723,20.529667 C6.82731872,20.5759434 4.92454074,20.4079483 5.00231625,19.5185853 C5.09525799,18.2104011 5.55413353,16.2597912 6.23505817,14.0218007 L6.13822766,14.0291894 C5.71396222,13.3264876 5.34997281,11.8335866 6.21289215,11.5691498 C6.50182819,11.4972075 6.78804209,11.4007658 7.10186629,11.3268791 C7.95739696,8.82911841 8.9972556,6.14547425 10.117223,3.55282744 C10.94631,3.48710713 13.0287494,4.67201711 12.6643712,5.33894216 C12.0289452,6.616405 11.1827476,8.64712371 10.355605,10.8967805 C11.5300153,10.8528373 12.4967649,10.9722227 13.2519652,11.2078826 C14.1720495,8.58218115 15.3165162,5.66987697 16.4539832,3.00256567 C17.2830701,2.93684536 19.3188443,4.14936565 18.9564104,4.84078998 Z M18.5566442,17.2572621 C18.8164144,17.4015357 19.0174641,17.6138629 18.998798,17.8829662 C18.9532993,18.5285029 18.5228119,19.2728146 17.991994,19.649637 C17.5708396,19.9984602 16.5729797,19.8386315 16.3937071,19.3214243 C16.133548,19.1771507 15.8967216,18.9621014 15.9169432,18.6754987 C15.962053,18.0299619 16.518148,17.2945944 16.9964674,16.895606 C17.4926752,16.4981731 18.3905935,16.7952756 18.5566442,17.2572621 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HankHLogoIcon;
