import * as React from 'react';
import IIconProp from '../iconInterface';

const BarcodeScannerIcon: React.FC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill={fillColor}
      d="M56 48c-4.4 0-8 3.6-8 8v80c0 13.3-10.7 24-24 24s-24-10.7-24-24V56C0 25.1 25.1 0 56 0h80c13.3 0 24 10.7 24 24s-10.7 24-24 24H56zm96 80h16c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24H152c-13.3 0-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm160 0h16c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24H312c-13.3 0-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm72 24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24H408c-13.3 0-24-10.7-24-24V152zM240 128c8.8 0 16 7.2 16 16V368c0 8.8-7.2 16-16 16s-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM528 56c0-4.4-3.6-8-8-8H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h80c30.9 0 56 25.1 56 56v80c0 13.3-10.7 24-24 24s-24-10.7-24-24V56zM56 464h80c13.3 0 24 10.7 24 24s-10.7 24-24 24H56c-30.9 0-56-25.1-56-56V376c0-13.3 10.7-24 24-24s24 10.7 24 24v80c0 4.4 3.6 8 8 8zm472-8V376c0-13.3 10.7-24 24-24s24 10.7 24 24v80c0 30.9-25.1 56-56 56H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h80c4.4 0 8-3.6 8-8z"
    />
  </svg>
);

export { BarcodeScannerIcon };
