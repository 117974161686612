import React from 'react';

export function BasketValue({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 576 512" className={className}>
      <g>
        <g>
          <path
            fill={fillColor}
            d="M336.8,364.4c0,10.1-3.7,18.3-11,24.6c-7.3,6.2-17.7,9.9-31,11.1v19.7h-13.2v-19.3c-15.6-0.3-29.4-3-41.1-8.3v-25.4
			c5.6,2.8,12.3,5.2,20.2,7.3c7.9,2.2,14.9,3.3,21,3.8v-29.8l-6.5-2.5c-12.7-5-21.7-10.5-27-16.3c-5.3-5.8-7.9-13.2-7.9-21.8
			c0-9.3,3.7-16.9,10.9-22.9c7.2-6,17.4-9.6,30.4-10.9v-14.7h13.2v14.4c14.7,0.6,27.9,3.6,39.8,8.8l-9.2,22.2
			c-10-4.1-20.3-6.6-30.8-7.4v28.4c12.5,4.9,21.4,9,26.7,12.5c5.3,3.6,9.2,7.4,11.7,11.7C335.6,353.8,336.8,358.8,336.8,364.4z
			 M269.2,307.5c0,2.8,1,5.2,2.9,7c1.9,1.8,5.1,3.7,9.5,5.6v-22.6C273.4,298.6,269.2,302,269.2,307.5z M307.9,365.8
			c0-2.7-1.1-5-3.2-6.8c-2.2-1.8-5.4-3.8-9.8-5.7v24C303.5,375.7,307.9,371.9,307.9,365.8z"
          />
        </g>
        <path
          fill={fillColor}
          d="M552,187.1h-67.3L377.9,28.9c-10.4-15.4-30.4-18.8-44.7-7.6c-14.4,11.1-17.5,32.7-7.1,48.1l79.4,117.6h-235l79.4-117.6
		c10.4-15.4,7.2-36.9-7.1-48.1c-14.2-11.2-34.3-7.9-44.7,7.6L91.4,187.1H24c-13.2,0-24,11.5-24,25.9v17.2C0,244.5,10.8,256,24,256
		H32L58.2,453c3.3,25.5,23.6,44.3,47.5,44.3h364.7c24,0,44.1-18.9,47.6-44.3L544.1,256h8c13.3,0,24-11.5,24-25.9V213
		C576,198.6,565.3,187.1,552,187.1z M287.4,236.6c38.4,0,71.8,21.5,88.7,53.1c7.7,14.2,12,30.4,12,47.6s-4.3,33.3-12,47.5
		c-16.9,31.6-50.3,53.1-88.7,53.1c-37.3,0-69.9-20.4-87.3-50.6c-8.4-14.7-13.3-31.8-13.3-50c0-18.1,4.9-35.3,13.3-50.1
		C217.4,257,250,236.6,287.4,236.6z M176,428.5c-13.3,0-24-11.7-24-25.9V281.9c0-14.4,10.7-25.9,24-25.9c9.7,0,18,6.2,21.9,15.1
		c-13.8,18.6-21.9,41.4-21.9,66.1c0,25.9,8.8,49.7,23.8,68.6C198.3,418.6,188.2,428.5,176,428.5z M424.1,402.6
		c0,14.2-10.8,25.9-24.1,25.9c-12.6,0-23-10.6-23.8-24c14.2-18.7,22.7-42,22.7-67.2c0-24.3-7.8-46.6-21-65
		c3.6-9.6,12.1-16.3,22.2-16.3c13.3,0,24.1,11.5,24.1,25.9V402.6z"
        />
      </g>
    </svg>
  );
}
