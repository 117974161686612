import IIconProp from '../../iconInterface';

export const GrateIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 448 512"
    >
      <path
        fill={fillColor}
        d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64h64 96 96 64c35.3 0 64-28.7 64-64V256 96c0-35.3-28.7-64-64-64H320 224 128 64zm0 64H96V224H64V96zm0 192H96V416H64V288zm96 128V288h32V416H160zm96 0V288h32V416H256zm96 0V288h32V416H352zm32-192H352V96h32V224zM288 96V224H256V96h32zm-96 0V224H160V96h32z"
      />
    </svg>
  );
};

export default GrateIcon;
