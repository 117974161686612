import * as React from 'react';
import IIconProp from '../iconInterface';

export const CategoriesIcon: React.VFC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#FFF',
}) => {
  return (
    <svg className={className} viewBox="0 0 23 17" version="1.1">
      <path
        d="M20.25 2.75H11.8769L9.08585 0H2.10824C0.952134 0 0.0149536 0.923398 0.0149536 2.0625V14.4375C0.0149536 15.5766 0.952134 16.5 2.10824 16.5H20.25C21.4061 16.5 22.3433 15.5766 22.3433 14.4375V4.8125C22.3433 3.6734 21.4061 2.75 20.25 2.75Z"
        fill={fillColor}
      />
      <mask
        id="mask0_1_266"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="17"
      >
        <path
          d="M20.25 2.75H11.8769L9.08585 0H2.10824C0.952134 0 0.0149536 0.923398 0.0149536 2.0625V14.4375C0.0149536 15.5766 0.952134 16.5 2.10824 16.5H20.25C21.4061 16.5 22.3433 15.5766 22.3433 14.4375V4.8125C22.3433 3.6734 21.4061 2.75 20.25 2.75Z"
          fill={fillColor}
        />
      </mask>
      <g mask="url(#mask0_1_266)">
        <rect
          x="-11.1492"
          y="-14"
          width="44.6567"
          height="44"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default CategoriesIcon;
