// *******************************************************
// ProductIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://fontawesome.com/v5.15/icons/boxes?style=solid
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
/**
 * ProductIcon
 *
 * @see {@link https://fontawesome.com/v5.15/icons/boxes?style=solid}
 */
export const ProductIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      viewBox="0 0 20 18"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="productIcon-asdasp"
          d="m18.75.5h-6.25v7.5l-2.5-1.6640625-2.5 1.6640625v-7.5h-6.25c-.6875 0-1.25.5625-1.25 1.25v15c0 .6875.5625 1.25 1.25 1.25h17.5c.6875 0 1.25-.5625 1.25-1.25v-15c0-.6875-.5625-1.25-1.25-1.25z"
        />
        <mask id="productIcon-lkajaodj" fill="#fff">
          <use fill="#fff" fillRule="evenodd" xlinkHref="#productIcon-asdasp" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#000" fillRule="nonzero" xlinkHref="#productIcon-asdasp" />
        <g mask="url(#productIcon-lkajaodj)">
          <path
            d="m0 0h44v44h-44z"
            fill={fillColor}
            transform="translate(-12 -13)"
          />
        </g>
      </g>
    </svg>
  );
};

export default ProductIcon;
