// *******************************************************
// Cube
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type ICubeIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const CubeIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: ICubeIconProps) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          fill={fillColor}
          id="juv6ioxr8a"
          d="M18.683.24L2.43 6.333A3.76 3.76 0 000 9.851V27.44c0 1.422.805 2.72 2.07 3.352l16.254 8.127a3.726 3.726 0 003.352 0l16.253-8.127A3.742 3.742 0 0040 27.44V9.85a3.746 3.746 0 00-2.43-3.508L21.317.247A3.696 3.696 0 0018.683.24zm1.32 4.852l15.004 5.626v.086l-15.003 6.095L5 10.804v-.086l15.003-5.626zm2.501 27.818V21.275l12.503-5.08V26.66l-12.503 6.25z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="he06df3oeb" fill={fillColor}>
          <use xlinkHref="#juv6ioxr8a" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#juv6ioxr8a" />
        <g fill={fillColor} mask="url(#he06df3oeb)">
          <path d="M-20-20h80v80h-80z" />
        </g>
      </g>
    </svg>
  );
};

export default CubeIcon;
