import React from 'react';

export function Transactions({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 25 34" className={className}>
      <title>Receipt</title>
      <defs>
        <path
          d="M23.098.208l-2.475 2.887L17.117.208a1.025 1.025 0 00-1.237 0l-3.506 2.887L8.868.208a1.025 1.025 0 00-1.238 0L4.125 3.095 1.65.208A1.03 1.03 0 000 1.033v30.934c0 .85.967 1.334 1.65.825l2.475-2.887 3.505 2.887c.366.277.872.277 1.238 0l3.506-2.887 3.506 2.887c.365.277.871.277 1.237 0l3.506-2.887 2.475 2.887c.676.51 1.65.026 1.65-.825V1.033a1.03 1.03 0 00-1.65-.825zm-2.475 22.994a.517.517 0 01-.516.516H4.64a.517.517 0 01-.515-.516v-1.03c0-.284.232-.516.515-.516h15.467c.284 0 .516.232.516.515v1.031zm0-6.186a.517.517 0 01-.516.515H4.64a.517.517 0 01-.515-.515v-1.032c0-.283.232-.515.515-.515h15.467c.284 0 .516.232.516.515v1.032zm0-6.187a.517.517 0 01-.516.515H4.64a.517.517 0 01-.515-.515V9.798c0-.284.232-.516.515-.516h15.467c.284 0 .516.232.516.516v1.03z"
          id="t_prefix__a"
        />
      </defs>
      <g transform="translate(-504 -143)" fill="none" fillRule="evenodd">
        <g transform="translate(504 143.5)">
          <mask id="t_prefix__b" fill="#fff">
            <use xlinkHref="#t_prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#t_prefix__a" />
          <g mask="url(#t_prefix__b)" fill={fillColor}>
            <path d="M-21-16.5h66v66h-66z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
