// *******************************************************
// MapMarkerIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const MapMarkerIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill={fillColor}
        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
      ></path>
    </svg>
  );
};

export default MapMarkerIcon;
