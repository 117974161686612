import React, { FunctionComponent } from 'react';
import IIconProp from '../iconInterface';

export const GuardWithStartIcon: FunctionComponent<
  React.PropsWithChildren<IIconProp>
> = ({ fillColor = '#000000', className = 'h-full w-auto' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1000 1000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill={fillColor}
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      >
        <path d="M2898.1,4985C2767.9,4883.5,944.4,3337.7,938.6,3324.3c-1.9-7.7,126.4-151.3,285.4-318c335.2-354.4,429.1-469.3,534.4-666.6c103.4-191.5,153.2-394.6,153.2-626.4c0-461.6-132.2-817.9-706.8-1905.9c-95.8-180-205-419.5-245.2-536.3l-72.8-210.7v-354.3c0-379.3,21.1-521,114.9-814.1c206.9-630.2,681.9-1166.5,1329.3-1501.7c287.3-147.5,511.4-233.7,871.5-329.4c940.5-252.8,1170.4-352.5,1597.5-685.8l197.3-155.1l147.5,118.8c436.7,348.6,683.8,459.7,1549.6,693.4c806.4,220.3,1256.5,455.9,1683.7,881.1c475,476.9,703,1001.8,729.8,1691.4c21.1,526.7,1.9,584.2-584.2,1710.5c-319.9,618.7-423.3,921.3-438.6,1304.4c-13.4,300.7,21.1,473.1,136,697.2c107.3,208.8,208.8,337.1,549.7,689.6c157.1,164.7,285.4,306.5,281.6,316c-5.8,17.3-1957.6,1668.4-1999.7,1691.3c-15.3,9.6-24.9,7.7-23-1.9c9.6-28.7-296.9-409.9-431-532.5c-172.4-162.8-306.5-226-498-235.6c-293.1-13.4-469.3,88.1-846.6,484.6l-256.7,270.1l-293.1-298.8C4442,4431.4,4396,4391.2,4260,4326.1c-145.6-70.9-162.8-74.7-335.2-74.7c-162.8,0-197.3,5.7-296.9,55.6c-61.3,30.6-170.5,111.1-243.3,178.1c-120.7,114.9-402.2,461.6-421.4,519.1C2957.5,5023.3,2940.3,5017.5,2898.1,4985z M5231.2,1081.3c178.1-365.8,233.7-461.6,268.2-469.3c23-5.7,252.8-40.2,511.4-78.5c256.7-36.4,469.3-69,473.1-70.9c1.9-3.8-162.8-172.4-365.9-375.4l-369.7-369.7l84.3-490.3c46-270.1,80.5-501.9,78.5-513.3c-3.8-11.5-203,84.3-446.3,214.5c-241.3,130.3-454,235.6-469.3,235.6s-226-105.3-469.3-235.6c-333.3-176.2-442.5-227.9-446.3-205c-1.9,17.2,34.5,247.1,78.5,513.3l84.3,480.8L3873.1,87.2c-203,203-367.8,371.6-365.8,375.4c3.8,1.9,216.5,34.5,473.1,70.9c258.6,38.3,488.4,72.8,511.4,78.5c34.5,7.7,90,105.4,266.2,469.3c124.5,252.8,229.9,459.7,237.5,459.7C5003.2,1541,5108.6,1334.1,5231.2,1081.3z" />
      </g>
    </svg>
  );
};

export default GuardWithStartIcon;
