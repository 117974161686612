import IIconProp from '../iconInterface';

export const PermissionsIcon = ({ fillColor = '#000' }: IIconProp) => {
  return (
    <svg
      className="w-auto"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          fill={fillColor}
          d="M27.8656735,5.1757203 L15.9895152,0.227321004 C15.2588856,-0.0757736681 14.4376957,-0.0757736681 13.7070661,0.227321004 L1.83090774,5.1757203 C0.723703397,5.63344724 0,6.71590958 0,7.91589641 C0,20.1941122 7.08239649,28.680617 13.7008806,31.4393496 C14.4307695,31.742439 15.2534408,31.742439 15.9833297,31.4393496 C21.2843025,29.2311264 29.6903958,21.604406 29.6903958,7.91589641 C29.6903958,6.71590958 28.9666924,5.63344724 27.8656735,5.1757203 Z M24.946118,12.2395603 L13.5647996,23.6208787 C13.1812986,24.0043796 12.5503777,24.0043796 12.1668768,23.6208787 L5.73395769,17.1879596 C5.35045674,16.8044587 5.35045674,16.1735377 5.73395769,15.7900368 L7.13188049,14.392114 C7.51538143,14.008613 8.14630234,14.008613 8.52980329,14.392114 L12.8658382,18.7281489 L22.1502724,9.4437147 C22.5337733,9.06021375 23.1646942,9.06021375 23.5481952,9.4437147 L24.946118,10.8416375 C25.3358044,11.2313239 25.3358044,11.8560594 24.946118,12.2395603 L24.946118,12.2395603 Z"
          id="PermissionsIconpath-1"
        ></path>
      </defs>
      <g
        id="PermissionsIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="PermissionsIconColours/Black-#000000"
          transform="translate(4.750000, 3.166667)"
        >
          <mask id="PermissionsIconmask-2" fill="white">
            <use xlinkHref="#PermissionsIconpath-1"></use>
          </mask>
          <use
            id="PermissionsIconMask"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#PermissionsIconpath-1"
          ></use>
          <g id="PermissionsIconGroup" mask="url(#PermissionsIconmask-2)">
            <g
              transform="translate(-20.583333, -19.000000)"
              id="PermissionsIconColours/Black-#000000"
            >
              <rect
                id="PermissionsIconRectangle"
                fill={fillColor}
                x="0"
                y="0"
                width="69.6666667"
                height="69.6666667"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PermissionsIcon;
