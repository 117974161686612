// *******************************************************
// TrashIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const TrashIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={fillColor}
        d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
      />
    </svg>
  );
};

export default TrashIcon;
