// *******************************************************
// ExchangeIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IExchangeIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const ExchangeIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IExchangeIconProps) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          fill={fillColor}
          id="0snh4cewya"
          d="M0 8.752v-1.25c0-1.036.84-1.875 1.875-1.875H30v-3.75C30 .207 32.023-.626 33.2.55l6.25 6.25c.733.732.733 1.92 0 2.652l-6.25 6.25c-1.172 1.172-3.2.35-3.2-1.326v-3.75H1.875A1.874 1.874 0 010 8.752zm38.125 11.875H10v-3.75c0-1.665-2.02-2.506-3.2-1.326l-6.25 6.25a1.874 1.874 0 000 2.652l6.25 6.25c1.173 1.174 3.2.349 3.2-1.326v-3.75h28.125c1.036 0 1.875-.84 1.875-1.875v-1.25c0-1.036-.84-1.875-1.875-1.875z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 8)">
        <mask id="9be34l9oqb" fill={fillColor}>
          <use xlinkHref="#0snh4cewya" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#0snh4cewya" />
        <g fill={fillColor} mask="url(#9be34l9oqb)">
          <path d="M-20-24h80v80h-80z" />
        </g>
      </g>
    </svg>
  );
};

export default ExchangeIcon;
