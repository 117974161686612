import IIconProp from '../../../iconInterface';

export const GraphMetricIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <g transform="translate(4 6)">
        <path
          id="ybwo9y0vca"
          fill={fillColor}
          d="M35.46 6.433c.375.146.78.234 1.207.234a3.334 3.334 0 10-3.334-3.334c0 .17.026.33.05.493l-6.621 5.296a3.316 3.316 0 00-1.207-.234 3.32 3.32 0 00-1.207.234l-6.62-5.296c.025-.161.05-.324.05-.493a3.334 3.334 0 00-6.667 0c0 .297.051.58.124.853l-7.049 7.049a3.329 3.329 0 00-.853-.124 3.333 3.333 0 103.334 3.333c0-.296-.052-.579-.124-.852l7.049-7.049c.273.072.556.124.852.124.428 0 .832-.088 1.207-.234l6.621 5.296c-.024.162-.05.324-.05.493a3.334 3.334 0 006.668 0c0-.169-.026-.33-.05-.492l6.62-5.297zm-7.682 15.79h-4.445c-.614 0-1.11.496-1.11 1.11v11.111a1.11 1.11 0 001.11 1.112h4.445a1.11 1.11 0 001.11-1.112v-11.11a1.11 1.11 0 00-1.11-1.112zm11.11-8.89h-4.444a1.11 1.11 0 00-1.11 1.111v20c0 .614.497 1.112 1.11 1.112h4.445c.614 0 1.111-.498 1.111-1.112v-20a1.11 1.11 0 00-1.111-1.11zm-22.221 0h-4.445a1.11 1.11 0 00-1.11 1.111v20a1.11 1.11 0 001.11 1.112h4.445a1.11 1.11 0 001.11-1.112v-20a1.11 1.11 0 00-1.11-1.11zM5.556 24.444H1.11c-.614 0-1.111.498-1.111 1.112v8.888c0 .614.497 1.112 1.111 1.112h4.445c.613 0 1.11-.498 1.11-1.112v-8.888c0-.614-.497-1.112-1.11-1.112z"
        />
      </g>
    </svg>
  );
};

export default GraphMetricIcon;
