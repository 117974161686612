// *******************************************************
// ManufacturerIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { VFC } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IManufacturerIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const ManufacturerIcon: VFC<IManufacturerIconProps> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      className={className}
    >
      <path
        fill={fillColor}
        d="M64 32C64 14.3 78.3 0 96 0H288c17.7 0 32 14.3 32 32V224c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V32zM384 96c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32V224c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V96zM0 416c0-53 43-96 96-96H544c53 0 96 43 96 96s-43 96-96 96H96c-53 0-96-43-96-96zm160 0c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm160 32c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm224-32c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"
      />
    </svg>
  );
};

export default ManufacturerIcon;
