import React from 'react';
import { IIconProp } from '../../../iconInterface';

export function SeasonalityIndexIcon({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}: IIconProp): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          d="M36 15.429v21.857a3.858 3.858 0 0 1-3.857 3.857H3.857A3.858 3.858 0 0 1 0 37.286V15.429h36zM30.834 20h-8.609c-1.559 0-2.34 1.885-1.237 2.987l2.362 2.363L18 30.7l-5.35-5.35a2.333 2.333 0 0 0-3.3 0l-5.008 5.01a1.167 1.167 0 0 0 0 1.65l1.65 1.65a1.167 1.167 0 0 0 1.65 0L11 30.3l5.35 5.35a2.333 2.333 0 0 0 3.3 0l7-7 2.363 2.362c1.102 1.103 2.987.322 2.987-1.237v-8.608c0-.645-.521-1.167-1.166-1.167zM11.571 0a1.29 1.29 0 0 1 1.286 1.286v3.857h10.286V1.286A1.29 1.29 0 0 1 24.429 0H27a1.29 1.29 0 0 1 1.286 1.286v3.857h3.857A3.858 3.858 0 0 1 36 9v3.857H0V9a3.858 3.858 0 0 1 3.857-3.857h3.857V1.286A1.29 1.29 0 0 1 9 0z"
          id="aSeasonIndexasdasdasda"
        />
      </defs>
      <g transform="translate(6 3)" fill="none" fillRule="evenodd">
        <mask id="bseasonnnalitydasodjaqwtet" fill={fillColor}>
          <use xlinkHref="#aSeasonIndexasdasdasda" />
        </mask>
        <use
          fill="#7C6565"
          fillRule="nonzero"
          xlinkHref="#aSeasonIndexasdasdasda"
        />
        <g mask="url(#bseasonnnalitydasodjaqwtet)" fill={fillColor}>
          <path d="M-26-23h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
}
