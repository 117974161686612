// *******************************************************
// SellThroughRateIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type ISellThroughRateIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const SellThroughRateIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: ISellThroughRateIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
      className={className}
    >
      <defs>
        <path
          fill={fillColor}
          id="etftmbe4za"
          d="M38.558 12.304A12.424 12.424 0 0140 18.222c-.052 6.887-5.738 12.406-12.625 12.406H15v3.71c0 1.756-2.045 2.521-3.2 1.365l-6.25-6.25a1.873 1.873 0 010-2.651l6.25-6.25c1.18-1.18 3.2-.34 3.2 1.326v3.75h12.412c4.126 0 7.545-3.296 7.588-7.422a7.467 7.467 0 00-.72-3.285 1.87 1.87 0 01.374-2.131l.928-.92c.888-.888 2.39-.675 2.976.434zM5 18.05c.043-4.126 3.462-7.421 7.588-7.421H25v3.71c0 1.749 2.04 2.525 3.2 1.365l6.25-6.25a1.873 1.873 0 000-2.651L28.2.552C27.033-.616 25 .179 25 1.92v3.708H12.625C5.737 5.628.052 11.146 0 18.033a12.421 12.421 0 001.442 5.918c.587 1.11 2.088 1.322 2.976.435l.928-.92c.564-.56.715-1.415.375-2.132a7.44 7.44 0 01-.72-3.285z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 6)">
        <mask id="l7ajh759sb" fill={fillColor}>
          <use xlinkHref="#etftmbe4za" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#etftmbe4za" />
        <g fill={fillColor} mask="url(#l7ajh759sb)">
          <path d="M-20-22h80v80h-80z" />
        </g>
      </g>
    </svg>
  );
};

export default SellThroughRateIcon;
