import React from 'react';
import IIconProp from '../../../iconInterface';

export function RecommendedLocalPriceIcon({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}: IIconProp): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          id="arkpalsdhasdianun"
          d="M16 0c8.837 0 16 7.163 16 16 0 6.451-2.247 8.253-14.356 25.806a2 2 0 0 1-3.288 0C2.248 24.253 0 22.45 0 16 0 7.163 7.163 0 16 0zm.667 6h-1.334c-.367 0-.667.3-.667.667v2h-.104a4.566 4.566 0 0 0-4.543 4.985c.175 1.92 1.642 3.484 3.493 4.026l4.271 1.25a1.229 1.229 0 0 1-.346 2.409h-2.763a2.544 2.544 0 0 1-1.425-.438.65.65 0 0 0-.812.083l-1.45 1.417a.676.676 0 0 0 .074 1.021 5.893 5.893 0 0 0 3.605 1.25v2.001c0 .367.3.667.667.667h1.334c.366 0 .666-.3.666-.667v-2.009c1.942-.037 3.764-1.192 4.406-3.03.896-2.567-.609-5.2-3.022-5.905l-4.5-1.317a1.229 1.229 0 0 1 .346-2.409h2.762c.508 0 1.009.154 1.425.438a.65.65 0 0 0 .813-.083l1.45-1.417a.676.676 0 0 0-.075-1.021 5.893 5.893 0 0 0-3.605-1.25V6.666c0-.367-.3-.667-.666-.667z"
        />
        <mask id="bnoREcommendedkhdaksdlasd" fill={fillColor}>
          <use fillRule="evenodd" xlinkHref="#arkpalsdhasdianun" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(8 2)">
        <use fill="#000" fillRule="nonzero" xlinkHref="#arkpalsdhasdianun" />
        <g fill={fillColor} mask="url(#bnoREcommendedkhdaksdlasd)">
          <path d="M-28-22h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
}
