// *******************************************************
// LostSalesIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type ILostSalesIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const LostSalesIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: ILostSalesIconProps) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          id="rcvbjjoita"
          fill={fillColor}
          d="M39.634 6.616L36.25 3.232a2.5 2.5 0 00-1.768-.732H22.5V1.25C22.5.56 21.94 0 21.25 0h-2.5c-.69 0-1.25.56-1.25 1.25V2.5H4.375C3.34 2.5 2.5 3.34 2.5 4.375v6.25c0 1.035.84 1.875 1.875 1.875h30.107a2.5 2.5 0 001.768-.732l3.384-3.384a1.25 1.25 0 000-1.768zM17.5 38.75c0 .691.56 1.25 1.25 1.25h2.5c.69 0 1.25-.559 1.25-1.25V30h-5v8.75zM35.625 17.5H22.5V15h-5v2.5H5.518a2.5 2.5 0 00-1.768.732L.366 21.617a1.25 1.25 0 000 1.768l3.384 3.383a2.5 2.5 0 001.768.732h30.107c1.035 0 1.875-.84 1.875-1.875v-6.25c0-1.035-.84-1.875-1.875-1.875z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="fb9c9cz45b" fill={fillColor}>
          <use xlinkHref="#rcvbjjoita" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#rcvbjjoita" />
        <g fill={fillColor} mask="url(#fb9c9cz45b)">
          <path d="M-20-20h80v80h-80z" />
        </g>
      </g>
    </svg>
  );
};

export default LostSalesIcon;
