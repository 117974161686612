import React from 'react';
import styles from './HeartbeatLoadingGrayIcon.module.css';

export const HeartbeatLoadingGrayIcon = () => {
  return (
    <svg
      // width="1440px"
      // height="177px"
      className={`h-full w-auto xl:h-auto xl:w-full`}
      viewBox="0 0 1440 177"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="56.8944209%"
          x2="50%"
          y2="100%"
          id="main-chart-loading-linearGradient"
        >
          <stop
            stopColor="#DDDDDD"
            offset="0%"
            className={styles.mainChartLoadingLinearGradientStopWithFullOpacity}
          />
          <stop stopColor="#DDDDDD" stopOpacity="0" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="applet-sEPBlhMCy5-heartbeat-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="sEPBlhMCy5-heartbeat-web-large-read-preloading"
          transform="translate(0.000000, -503.000000)"
          fill="url(#main-chart-loading-linearGradient)"
        >
          <polygon
            id="Path-2"
            points="0 587.744867 66.3003034 538.128661 118.827451 536.578155 165.538221 530.376129 225.591518 536.578155 265.081683 544.330687 322.48646 533.477142 373.662314 612.55297 422.356325 638.91158 472.573917 559.835751 526.380209 503 580.689279 583.093348 625.057073 536.578155 673.641339 533.477142 728.760074 541.229674 769.873779 552.083219 793.944252 511.770052 823.838453 587.744867 876.778146 528.825623 926.19016 586.194361 981.113095 604.800438 1029.2613 646.664112 1084.90224 533.477142 1133.59625 559.835751 1177.58483 536.578155 1230.11207 513.862908 1285.66277 587.744867 1338.6927 541.229674 1386.42845 524.174103 1440 562.936764 1440 680 0 680"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};

export default HeartbeatLoadingGrayIcon;
