import React, { ReactElement } from 'react';
import IIconProp from '../../../iconInterface';

export const PercentageIcon = ({
  className = 'h-full w-auto',
  fillColor = '#F28A14',
}: IIconProp): ReactElement => {
  return (
    <svg
      viewBox="0 0 384 512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={fillColor}
        d="m374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0zm-246.6 9.4a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zm256 256a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"
      />
    </svg>
  );
};

export default PercentageIcon;
