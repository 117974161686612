import { FC } from 'react';
import IIconProp from '../../iconInterface';

export const ArrowAboveDotIcon: FC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '000',
}) => {
  return (
    <svg
      viewBox="0 0 576 512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={fillColor}
        d="m160 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7l-201.3 201.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l201.3-201.4v50.7c0 17.7 14.3 32 32 32s32-14.3 32-32v-128c0-17.7-14.3-32-32-32zm416 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-128 128a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-48 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm128 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-304-80a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-176 48a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm432-176a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-48-80a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
      />
    </svg>
  );
};

export default ArrowAboveDotIcon;
