import * as React from 'react';
import { IIconProp } from '../iconInterface';

const RepeteIcon: React.VFC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => {
  return (
    <svg
      aria-hidden="true"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 32"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="loop-svgrepo-com"
          transform="translate(0.000000, 0.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <polygon
            id="Path"
            points="-1.77635684e-15 25.3334 0.0001 -3.55271368e-15 44.3334 -3.55271368e-15 44.3334 25.3334 26.5208 25.3335 33.2499 31.6668 22.9583 31.6668 12.6666 22.1668 22.9583 12.6668 33.2499 12.6668 26.5208 19.0001 38 19 38 6.3334 6.3334 6.3334 6.3334 19 12.2709 19 9.5 22.1667 11.875 25.3334"
          />
        </g>
      </g>
    </svg>
  );
};

export { RepeteIcon };
