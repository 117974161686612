import * as React from 'react';
import IIconProp from '../iconInterface';

const CartIcon: React.FC<IIconProp> = ({
  fillColor = '#000000',
  className = 'h-full w-auto',
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3334 10C45.3052 10 43.6667 11.6385 43.6667 13.6667V50.7458L4.00944 61.5625C3.51673 61.7 3.23027 62.2041 3.36777 62.6854L4.81152 67.9906C4.94902 68.4833 5.45319 68.7697 5.93444 68.6322L51.0459 56.326C51.3209 63.1781 56.9125 68.6666 63.8333 68.6666C70.926 68.6666 76.6667 62.926 76.6667 55.8333V10H47.3334ZM9.08548 53.0375C9.34902 54.0114 10.3574 54.5958 11.3313 54.3322L36.1271 47.6864C37.1011 47.4229 37.6855 46.4146 37.4219 45.4406L31.7271 24.1854C31.4636 23.2114 30.4552 22.6271 29.4813 22.8906L20.6355 25.2739L23.4771 35.8958L16.3959 37.7864L13.5542 27.1646L4.69694 29.5364C3.72298 29.8 3.13861 30.8083 3.40215 31.7823L9.08548 53.0375ZM58.3333 55.8333C58.3333 58.8698 60.7969 61.3333 63.8333 61.3333C66.8698 61.3333 69.3333 58.8698 69.3333 55.8333C69.3333 52.7968 66.8698 50.3333 63.8333 50.3333C60.7969 50.3333 58.3333 52.7968 58.3333 55.8333Z"
        fill={fillColor}
      />
      <mask
        id="mask0_0_119"
        maskUnits="userSpaceOnUse"
        x="3"
        y="10"
        width="74"
        height="59"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.3334 10C45.3052 10 43.6667 11.6385 43.6667 13.6667V50.7458L4.00944 61.5625C3.51673 61.7 3.23027 62.2041 3.36777 62.6854L4.81152 67.9906C4.94902 68.4833 5.45319 68.7697 5.93444 68.6322L51.0459 56.326C51.3209 63.1781 56.9125 68.6666 63.8333 68.6666C70.926 68.6666 76.6667 62.926 76.6667 55.8333V10H47.3334ZM9.08548 53.0375C9.34902 54.0114 10.3574 54.5958 11.3313 54.3322L36.1271 47.6864C37.1011 47.4229 37.6855 46.4146 37.4219 45.4406L31.7271 24.1854C31.4636 23.2114 30.4552 22.6271 29.4813 22.8906L20.6355 25.2739L23.4771 35.8958L16.3959 37.7864L13.5542 27.1646L4.69694 29.5364C3.72298 29.8 3.13861 30.8083 3.40215 31.7823L9.08548 53.0375ZM58.3333 55.8333C58.3333 58.8698 60.7969 61.3333 63.8333 61.3333C66.8698 61.3333 69.3333 58.8698 69.3333 55.8333C69.3333 52.7968 66.8698 50.3333 63.8333 50.3333C60.7969 50.3333 58.3333 52.7968 58.3333 55.8333Z"
          fill={fillColor}
        />
      </mask>
      <g mask="url(#mask0_0_119)">
        <rect
          x="-33.3333"
          y="-33.3333"
          width="146.667"
          height="146.667"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export { CartIcon };
