// *******************************************************
// ShrinkageIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IShrinkageIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const ShrinkageIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IShrinkageIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
      className={className}
    >
      <defs>
        <path
          fill={fillColor}
          id="yh2vzvy6ya"
          d="M.418 35.296l8.868-8.867-2.94-2.77C4.996 22.308 5.952 20 7.861 20h10A2.14 2.14 0 0120 22.143v10c0 1.909-2.304 2.865-3.654 1.515l-2.775-2.944-8.867 8.868a1.43 1.43 0 01-2.02 0L.418 37.316a1.43 1.43 0 010-2.02zM39.582 4.704l-8.868 8.867 2.94 2.77c1.35 1.35.394 3.659-1.515 3.659h-10A2.14 2.14 0 0120 17.857v-10c0-1.909 2.304-2.865 3.654-1.515l2.775 2.944L35.296.418a1.43 1.43 0 012.02 0l2.266 2.266a1.43 1.43 0 010 2.02z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="rvj1m6dg5b" fill={fillColor}>
          <use xlinkHref="#yh2vzvy6ya" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#yh2vzvy6ya" />
        <g fill={fillColor} mask="url(#rvj1m6dg5b)">
          <path d="M-20-20h80v80h-80z" />
        </g>
      </g>
    </svg>
  );
};

export default ShrinkageIcon;
