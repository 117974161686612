// *******************************************************
// SandwichIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const SandwichIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Deli</title>
      <defs>
        <path
          d="M18.75,0 L1.25,0 C0.559644063,0 0,0.559644063 0,1.25 L0,5 C0,5.69035594 0.559644063,6.25 1.25,6.25 L18.75,6.25 C19.4403559,6.25 20,5.69035594 20,5 L20,1.25 C20,0.559644063 19.4403559,0 18.75,0 Z M19.4492188,8.72226563 C18.8601562,8.65664062 18.5117188,8.48125 18.0621094,8.25742187 C17.3875,7.91992188 16.5492187,7.5 15.0046875,7.5 C13.4601563,7.5 12.621875,7.91992188 11.9476563,8.25703125 C11.3757813,8.54296875 10.9625,8.75 10.0085938,8.75 C9.0546875,8.75 8.63828125,8.54296875 8.06523438,8.25703125 C7.39023438,7.91992188 6.55039063,7.5 5.00429688,7.5 C3.45820312,7.5 2.6171875,7.91914062 1.94414063,8.25703125 C1.49453125,8.48125 1.14296875,8.65703125 0.55078125,8.72226563 C0.237372468,8.75850846 0.000684117166,9.02356584 0,9.3390625 L0,10.5945312 C0.00199240217,10.7725487 0.0786043446,10.9415583 0.211171739,11.0603863 C0.343739132,11.1792142 0.520091314,11.2369511 0.697265625,11.2195312 C1.81367188,11.1167969 2.49414062,10.7757812 3.0609375,10.4933594 C3.6328125,10.2070312 4.0484375,10 5.00429688,10 C5.96015625,10 6.375,10.2070312 6.9484375,10.4929687 C7.62265625,10.8308594 8.46328125,11.25 10.0085938,11.25 C11.5539063,11.25 12.3914063,10.8300781 13.065625,10.4929687 C13.6375,10.2070312 14.0507812,10 15.0046875,10 C15.9585938,10 16.371875,10.2070312 16.9425781,10.4925781 C17.5078125,10.7753906 18.1882812,11.1175781 19.3023437,11.2191406 C19.4795549,11.2365546 19.6559437,11.1788276 19.7885711,11.0600125 C19.9211985,10.9411974 19.9978986,10.772193 20,10.5941406 L20,9.33867188 C19.9989427,9.02339171 19.7623933,8.75865775 19.4492188,8.72226563 L19.4492188,8.72226563 Z M18.75,12.5 L16.25,12.5 L12.5,14.375 L8.75,12.5 L1.25,12.5 C0.559644063,12.5 0,13.0596441 0,13.75 L0,16.25 C0,16.9403559 0.559644063,17.5 1.25,17.5 L18.75,17.5 C19.4403559,17.5 20,16.9403559 20,16.25 L20,13.75 C20,13.0596441 19.4403559,12.5 18.75,12.5 Z"
          id="path-1-deli-sandwich-khg"
        ></path>
      </defs>
      <g
        id="00-Micronaires/Icons/Deli"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Colours/Black-#000000-deli-sandwich-khg"
          transform="translate(2.000000, 3.000000)"
        >
          <mask id="mask-2-deli-sandwich-khg" fill="white">
            <use xlinkHref="#path-1-deli-sandwich-khg"></use>
          </mask>
          <use
            id="Mask-deli-sandwich-khg"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#path-1-deli-sandwich-khg"
          ></use>
          <g id="Group-deli-sandwich-khg" mask="url(#mask-2-deli-sandwich-khg)">
            <g transform="translate(-12.000000, -13.000000)">
              <rect
                id="Rectangle-deli-sandwich-khg"
                fill={fillColor}
                x="0"
                y="0"
                className={className}
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SandwichIcon;
