import React from 'react';

export function TotalSales({
  className = 'h-full w-auto',
  fillColor = '#000',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
      className={className}
    >
      <defs>
        <path
          id="flnfwrl1fa"
          d="M16.34 18.234l-8.437-2.468a2.304 2.304 0 01.649-4.516h5.18c.953 0 1.89.29 2.671.82a1.22 1.22 0 001.524-.156l2.719-2.656c.554-.54.476-1.438-.141-1.914A11.05 11.05 0 0013.747 5V1.25c0-.688-.562-1.25-1.25-1.25h-2.5c-.687 0-1.25.563-1.25 1.25V5h-.195C3.575 5-.425 9.273.036 14.344c.328 3.601 3.078 6.531 6.547 7.547l8.008 2.343a2.32 2.32 0 011.656 2.211 2.304 2.304 0 01-2.305 2.305h-5.18c-.952 0-1.89-.29-2.671-.82a1.22 1.22 0 00-1.524.156L1.85 30.742c-.555.54-.477 1.438.14 1.914A11.05 11.05 0 008.748 35v3.75c0 .688.563 1.25 1.25 1.25h2.5c.688 0 1.25-.563 1.25-1.25v-3.766c3.64-.07 7.055-2.234 8.258-5.68 1.68-4.812-1.14-9.75-5.664-11.07z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(12 4)">
        <mask id="cbpzadvkcb" fill="#fff">
          <use xlinkHref="#flnfwrl1fa" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#flnfwrl1fa" />
        <g fill={fillColor} mask="url(#cbpzadvkcb)">
          <path d="M-32-24h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
}
