import React, { FunctionComponent } from 'react';

interface IMeritScoreDiamondIconProps {
  fillColor?: string;
  className?: string;
  importance?: string;
}

export const MeritScoreDiamondIcon: FunctionComponent<
  React.PropsWithChildren<IMeritScoreDiamondIconProps>
> = ({ fillColor = '#000', className = 'h-full w-auto', importance }) => {
  switch (importance) {
    case 'critical':
      fillColor = '#f28a14';
      break;
    case 'high':
      fillColor = '#5a3b8f';
      break;
    case 'medium':
      fillColor = '#23b0e7';
      break;
    case 'low':
      fillColor = '#889aa4';
      break;
    case 'trivial':
      fillColor = '#e7ebed';
      break;
    default:
      break;
  }

  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillColor}
        d="m12 2 10 10-10 10-10-10zm0 6.5-3.5 3.5 3.5 3.5 3.5-3.5z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MeritScoreDiamondIcon;
