export { default as Dairy1 } from './images/Dairy/Dairy1.jpeg';
export { default as Dairy2 } from './images/Dairy/Dairy2.jpeg';
export { default as Dairy3 } from './images/Dairy/Dairy3.jpeg';
export { default as Dairy4 } from './images/Dairy/Dairy4.jpeg';
export { default as Dairy5 } from './images/Dairy/Dairy5.jpeg';
export { default as Dairy6 } from './images/Dairy/Dairy6.jpeg';
export { default as Dairy7 } from './images/Dairy/Dairy7.jpeg';

export { default as Deli1 } from './images/Deli/Deli1.jpeg';
export { default as Deli2 } from './images/Deli/Deli2.jpeg';
export { default as Deli3 } from './images/Deli/Deli3.jpeg';
export { default as Deli4 } from './images/Deli/Deli4.jpeg';
export { default as Deli5 } from './images/Deli/Deli5.jpeg';
export { default as Deli6 } from './images/Deli/Deli6.jpeg';
export { default as Deli7 } from './images/Deli/Deli7.jpeg';

export { default as Grocery1 } from './images/Grocery/Grocery1.jpeg';
export { default as Grocery2 } from './images/Grocery/Grocery2.jpeg';
export { default as Grocery3 } from './images/Grocery/Grocery3.jpeg';
export { default as Grocery4 } from './images/Grocery/Grocery4.jpeg';
export { default as Grocery5 } from './images/Grocery/Grocery5.jpeg';
export { default as Grocery6 } from './images/Grocery/Grocery6.jpeg';
export { default as Grocery7 } from './images/Grocery/Grocery7.jpeg';

export { default as Meat1 } from './images/Meat/Meat1.jpeg';
export { default as Meat2 } from './images/Meat/Meat2.jpeg';
export { default as Meat3 } from './images/Meat/Meat3.jpeg';
export { default as Meat4 } from './images/Meat/Meat4.jpeg';
export { default as Meat5 } from './images/Meat/Meat5.jpeg';
export { default as Meat6 } from './images/Meat/Meat6.jpeg';
export { default as Meat7 } from './images/Meat/Meat7.jpeg';

export { default as Produce1 } from './images/Produce/Produce1.jpeg';
export { default as Produce2 } from './images/Produce/Produce2.jpeg';
export { default as Produce3 } from './images/Produce/Produce3.jpeg';
export { default as Produce4 } from './images/Produce/Produce4.jpeg';
export { default as Produce5 } from './images/Produce/Produce5.jpeg';
export { default as Produce6 } from './images/Produce/Produce6.jpeg';
export { default as Produce7 } from './images/Produce/Produce7.jpeg';

export { default as MockBarcode } from './images/bar_code.png';
