// *******************************************************
// BaskeMarginIcon
// -------------------------------------------------------
// Component Description
// A basket icon with a percentage icon inside
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const BasketMarginIcon = ({
  className = 'h-full w-auto',
  fillColor = '#F28A14',
}: IIconProp): ReactElement => {
  return (
    <svg className={className} viewBox="0 0 576 512">
      <g>
        <g>
          <path
            fill={fillColor}
            d="M219,308.9c0-4,0.8-7.9,2.3-11.3c1.5-3.6,3.6-6.6,6.3-9.3c2.6-2.7,5.7-4.7,9.3-6.3c3.6-1.5,7.3-2.3,11.4-2.3
			s7.9,0.8,11.3,2.3c3.6,1.5,6.6,3.6,9.3,6.3c2.7,2.7,4.7,5.7,6.3,9.3c1.5,3.6,2.3,7.3,2.3,11.3s-0.8,7.9-2.3,11.4
			c-1.5,3.6-3.6,6.7-6.3,9.3c-2.7,2.6-5.7,4.7-9.3,6.3c-3.6,1.5-7.3,2.3-11.3,2.3c-4,0-7.9-0.8-11.4-2.3s-6.7-3.6-9.3-6.3
			c-2.6-2.6-4.7-5.7-6.3-9.3C219.8,316.8,219,313,219,308.9z M239.3,308.9c0,2.6,0.9,4.7,2.5,6.4c1.6,1.6,3.8,2.5,6.4,2.5
			c1.2,0,2.4-0.2,3.5-0.6c1.1-0.4,2.1-1.1,2.8-1.8c0.8-0.8,1.4-1.7,1.8-2.8c0.4-1.1,0.6-2.3,0.6-3.6c0-1.3-0.2-2.4-0.6-3.5
			c-0.4-1.1-1.1-1.9-1.8-2.8c-0.8-0.8-1.7-1.4-2.8-1.8c-1.1-0.4-2.3-0.6-3.5-0.6c-1.3,0-2.5,0.2-3.6,0.6c-1.1,0.4-2.1,1.1-2.8,1.8
			c-0.9,0.8-1.4,1.7-1.8,2.8C239.6,306.5,239.3,307.6,239.3,308.9z M297.8,286.2c0.4-0.9,1-1.7,1.8-2.4c0.8-0.6,1.8-1,3-1h16.4
			c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.2,1,1.2,1.7c0,0.5,0,0.9-0.1,1L274.9,392c-0.5,1.3-1.1,2.2-1.6,2.6c-0.5,0.4-1.3,0.8-2.4,0.8
			h-18.1c-2.2,0-3.2-0.6-3.2-1.9l0.1-0.3l0.2-1.1L297.8,286.2z M294.8,367.7c0-4,0.8-7.9,2.3-11.3c1.5-3.6,3.6-6.6,6.3-9.3
			c2.6-2.7,5.7-4.7,9.3-6.3c3.6-1.5,7.3-2.3,11.4-2.3c4.1,0,7.9,0.8,11.3,2.3c3.6,1.5,6.6,3.6,9.3,6.3c2.7,2.7,4.7,5.7,6.3,9.3
			c1.5,3.6,2.3,7.3,2.3,11.3s-0.8,7.9-2.3,11.4c-1.5,3.6-3.6,6.7-6.3,9.3c-2.7,2.6-5.7,4.7-9.3,6.3S328,397,324,397
			s-7.9-0.8-11.4-2.3c-3.6-1.5-6.7-3.6-9.3-6.3s-4.7-5.7-6.3-9.3C295.6,375.6,294.8,371.8,294.8,367.7z M315.1,367.7
			c0,2.6,0.9,4.7,2.5,6.4c1.6,1.6,3.8,2.5,6.4,2.5c1.2,0,2.4-0.2,3.5-0.6s2.1-1.1,2.8-1.8c0.8-0.8,1.4-1.7,1.8-2.8s0.6-2.3,0.6-3.6
			c0-1.3-0.2-2.4-0.6-3.5c-0.4-1.1-1.1-1.9-1.8-2.8c-0.8-0.8-1.7-1.4-2.8-1.8c-1.1-0.4-2.3-0.6-3.5-0.6c-1.3,0-2.5,0.2-3.6,0.6
			c-1.1,0.4-1.9,1.1-2.8,1.8c-0.9,0.8-1.4,1.7-1.8,2.8C315.3,365.3,315.1,366.5,315.1,367.7z"
          />
        </g>
        <path
          fill={fillColor}
          d="M552,187.1h-67.3L377.9,28.9c-10.4-15.4-30.4-18.8-44.7-7.6c-14.4,11.1-17.5,32.7-7.1,48.1l79.4,117.6h-235l79.4-117.6
		c10.4-15.4,7.2-36.9-7.1-48.1c-14.2-11.2-34.3-7.9-44.7,7.6L91.4,187.1H24c-13.2,0-24,11.5-24,25.9v17.2C0,244.5,10.8,256,24,256
		H32L58.2,453c3.3,25.5,23.6,44.3,47.5,44.3h364.7c24,0,44.1-18.9,47.6-44.3L544.1,256h8c13.3,0,24-11.5,24-25.9V213
		C576,198.6,565.3,187.1,552,187.1z M287.4,236.6c38.4,0,71.8,21.5,88.7,53.1c7.7,14.2,12,30.4,12,47.6s-4.3,33.3-12,47.5
		c-16.9,31.6-50.3,53.1-88.7,53.1c-37.3,0-69.9-20.4-87.3-50.6c-8.4-14.7-13.3-31.8-13.3-50c0-18.1,4.9-35.3,13.3-50.1
		C217.4,257,250,236.6,287.4,236.6z M176,428.5c-13.3,0-24-11.7-24-25.9V281.9c0-14.4,10.7-25.9,24-25.9c9.7,0,18,6.2,21.9,15.1
		c-13.8,18.6-21.9,41.4-21.9,66.1c0,25.9,8.8,49.7,23.8,68.6C198.3,418.6,188.2,428.5,176,428.5z M424.1,402.6
		c0,14.2-10.8,25.9-24.1,25.9c-12.6,0-23-10.6-23.8-24c14.2-18.7,22.7-42,22.7-67.2c0-24.3-7.8-46.6-21-65
		c3.6-9.6,12.1-16.3,22.2-16.3c13.3,0,24.1,11.5,24.1,25.9V402.6z"
        />
      </g>
    </svg>
  );
};

export default BasketMarginIcon;
