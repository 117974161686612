// *******************************************************
// UserCostIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IUserCostIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const UserCostIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IUserCostIconProps) => {
  return (
    <svg
      className={className}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          fill={fillColor}
          id="hagz7v4rta"
          d="M10.624 20.25a12.282 12.282 0 005.126 1.125c1.828 0 3.565-.408 5.126-1.125h1.174c5.217 0 9.45 4.233 9.45 9.45v2.925A3.376 3.376 0 0128.125 36H3.375A3.376 3.376 0 010 32.625V29.7c0-5.217 4.233-9.45 9.45-9.45zM33 6c.275 0 .5.225.5.5V8a4.42 4.42 0 012.703.938c.247.19.278.55.056.765l-1.087 1.063a.489.489 0 01-.61.062 1.908 1.908 0 00-1.068-.328H31.42a.921.921 0 00-.26 1.806l3.375.988a3.431 3.431 0 012.266 4.428c-.481 1.378-1.847 2.244-3.303 2.272V21.5c0 .275-.225.5-.5.5h-1a.501.501 0 01-.5-.5V20a4.411 4.411 0 01-2.703-.938.506.506 0 01-.057-.765l1.088-1.063a.489.489 0 01.61-.062c.312.212.687.328 1.068.328h2.072a.922.922 0 00.259-1.806l-3.203-.938c-1.387-.406-2.487-1.578-2.619-3.018A3.426 3.426 0 0131.421 8h.078V6.5c0-.275.225-.5.5-.5zM15.75 0c4.971 0 9 4.029 9 9s-4.029 9-9 9-9-4.029-9-9 4.029-9 9-9z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(6 6)">
        <mask id="ljdqgjaslb" fill={fillColor}>
          <use xlinkHref="#hagz7v4rta" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#hagz7v4rta" />
        <g fill={fillColor} mask="url(#ljdqgjaslb)">
          <path d="M-26-26h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
};

export default UserCostIcon;
