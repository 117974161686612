import React from 'react';

export function Satisfaction({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 30 30" className={className}>
      <title>Satisfaction</title>
      <defs>
        <path
          d="M15 0C6.714 0 0 6.714 0 15s6.714 15 15 15 15-6.714 15-15S23.286 0 15 0zm4.839 10.161c1.07 0 1.935.865 1.935 1.936 0 1.07-.865 1.935-1.935 1.935a1.933 1.933 0 01-1.936-1.935c0-1.07.865-1.936 1.936-1.936zm-9.678 0c1.07 0 1.936.865 1.936 1.936 0 1.07-.865 1.935-1.936 1.935a1.933 1.933 0 01-1.935-1.935c0-1.07.865-1.936 1.935-1.936zm11.783 10.295A9.01 9.01 0 0115 23.71a9.01 9.01 0 01-6.944-3.254c-.822-.986.666-2.22 1.488-1.24A7.068 7.068 0 0015 21.768c2.117 0 4.1-.931 5.456-2.552.81-.98 2.304.254 1.488 1.24z"
          id="s_prefix__a"
        />
      </defs>
      <g transform="translate(-661 -145)" fill="none" fillRule="evenodd">
        <g transform="translate(661 145)">
          <mask id="s_prefix__b" fill="#fff">
            <use xlinkHref="#s_prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#s_prefix__a" />
          <g mask="url(#s_prefix__b)" fill={fillColor}>
            <path d="M-18-18h66v66h-66z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
