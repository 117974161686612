import React from 'react';

export function YearVsYear({
  fillColor = '#F28A14',
  className = 'h-full w-auto',
}) {
  return (
    <svg viewBox="0 0 34 27" className={className}>
      <title>Year comparaison</title>
      <defs>
        <path
          d="M4.95 17.325V6.6H2.475A2.475 2.475 0 000 9.075v14.85A2.475 2.475 0 002.475 26.4h21.45a2.475 2.475 0 002.475-2.475V21.45H9.075a4.13 4.13 0 01-4.125-4.125zM30.525 3.3h-9.9l-3.3-3.3h-8.25A2.475 2.475 0 006.6 2.475v14.85A2.475 2.475 0 009.075 19.8h21.45A2.475 2.475 0 0033 17.325V5.775A2.475 2.475 0 0030.525 3.3z"
          id="yvy_prefix__a"
        />
      </defs>
      <g transform="translate(-819 -146)" fill="none" fillRule="evenodd">
        <g transform="translate(819.5 146.5)">
          <mask id="yvy_prefix__b" fill="#fff">
            <use xlinkHref="#yvy_prefix__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#yvy_prefix__a" />
          <g mask="url(#yvy_prefix__b)" fill={fillColor}>
            <path d="M-16.5-19.5h66v66h-66z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
