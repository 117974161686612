// *******************************************************
// CartPlusIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const CartPlusIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Add to cart</title>
      <defs>
        <path
          d="M17.5249004,11.111114 L7.3462519,11.111114 L7.5735089,12.2222254 L16.8935807,12.2222254 C17.4283378,12.2222254 17.8246921,12.718788 17.7061851,13.2402465 L17.5146226,14.0831634 C18.1636158,14.3981982 18.6111159,15.0634761 18.6111159,15.8333374 C18.6111159,16.9167405 17.7250393,17.793199 16.6380598,17.777574 C15.6025387,17.7626782 14.7509066,16.9223308 14.7229552,15.8870527 C14.7076774,15.3215317 14.93424,14.8089622 15.3063928,14.4444134 L8.02694652,14.4444134 C8.38725911,14.7973997 8.61111333,15.2891012 8.61111333,15.8333374 C8.61111333,16.9379558 7.69000198,17.8274699 6.57396003,17.7756296 C5.58298755,17.7296226 4.77704984,16.928928 4.7249665,15.9382333 C4.68475815,15.1731636 5.0873277,14.4988232 5.6989598,14.1470523 L3.25982723,2.2222228 L0.833333548,2.2222228 C0.373090374,2.2222228 0,1.84913242 0,1.38888925 L0,0.833333548 C0,0.373090374 0.373090374,0 0.833333548,0 L4.39336919,0 C4.78923735,0 5.13045271,0.278507016 5.20979301,0.666319616 L5.52805698,2.2222228 L19.1663244,2.2222228 C19.7010815,2.2222228 20.0974357,2.71878542 19.9789288,3.24024389 L18.3375047,10.462468 C18.2512894,10.8418778 17.9139977,11.111114 17.5249004,11.111114 Z M14.1666703,5.83333484 L12.5000032,5.83333484 L12.5000032,4.44444559 C12.5000032,4.13760523 12.2512879,3.88888989 11.9444475,3.88888989 L11.3888918,3.88888989 C11.0820515,3.88888989 10.8333361,4.13760523 10.8333361,4.44444559 L10.8333361,5.83333484 L9.16666903,5.83333484 C8.85982867,5.83333484 8.61111333,6.08205018 8.61111333,6.38888989 L8.61111333,6.94444624 C8.61111333,7.25128659 8.85982867,7.50000194 9.16666903,7.50000194 L10.8333361,7.50000194 L10.8333361,8.88888989 C10.8333361,9.19573154 11.0820515,9.44444688 11.3888918,9.44444688 L11.9444475,9.44444688 C12.2512879,9.44444688 12.5000032,9.19573154 12.5000032,8.88888989 L12.5000032,7.50000194 L14.1666703,7.50000194 C14.4735107,7.50000194 14.722226,7.25128659 14.722226,6.94444624 L14.722226,6.38888989 C14.722226,6.08205018 14.4735107,5.83333484 14.1666703,5.83333484 Z"
          id="path-1-cart-plus-lasihf"
        ></path>
      </defs>
      <g
        id="00-Micronaires/Icons/Cart-plus"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Colours/Black-#000000-cart-plus-sda"
          transform="translate(2.000000, 3.000000)"
        >
          <mask id="mask-2-cart-plus-kasjgd" fill="white">
            <use xlinkHref="#path-1-cart-plus-lasihf"></use>
          </mask>
          <use
            id="Mask-cacapluscart"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#path-1-cart-plus-lasihf"
          ></use>
          <g id="Group-cart-plus-asd" mask="url(#mask-2-cart-plus-kasjgd)">
            <g transform="translate(-12.000000, -13.000000)">
              <rect
                id="Rectangle-cart-plus"
                fill={fillColor}
                x="0"
                y="0"
                width="44"
                height="44"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CartPlusIcon;
