import * as React from 'react';
import IIconProp from '../../iconInterface';

const DevModeIcon: React.FC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg
    viewBox="0 0 576 512"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill={fillColor}
      d="m0 64c0-35.3 28.7-64 64-64h448c35.3 0 64 28.7 64 64v288c0 35.3-28.7 64-64 64h-176l10.7 32h69.3c17.7 0 32 14.3 32 32s-14.3 32-32 32h-256c-17.7 0-32-14.3-32-32s14.3-32 32-32h69.3l10.7-32h-176c-35.3 0-64-28.7-64-64zm64 0v288h448v-288zm185 113-31 31 31 31c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-48-48c-9.4-9.4-9.4-24.6 0-33.9l48-48c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zm112-34 48 48c9.4 9.4 9.4 24.6 0 33.9l-48 48c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l31-31-31-31c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"
    />
  </svg>
);

export { DevModeIcon };
