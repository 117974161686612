// *******************************************************
// StockTurnIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IStockTurnIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const StockTurnIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IStockTurnIconProps) => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      viewBox="0 0 48 48"
    >
      <defs>
        <path
          fill={fillColor}
          id="ncn6txqoya"
          d="M40 17.5v18.75c0 2.07-1.68 3.75-3.75 3.75H3.75C1.68 40 0 38.32 0 36.25V17.5h40zM20.017 20C15.052 19.991 11 24.035 11 29a9 9 0 0015.03 6.68.435.435 0 00.018-.632l-1.44-1.44a.435.435 0 00-.593-.02A6.05 6.05 0 0120 35.097c-3.405 0-6.069-2.758-6.097-6.044-.029-3.465 2.798-6.179 6.151-6.15a6.06 6.06 0 014.11 1.643l-1.516 1.516c-.549.548-.16 1.486.616 1.486h4.865a.87.87 0 00.871-.87v-4.866c0-.776-.938-1.164-1.487-.615l-1.296 1.296a8.967 8.967 0 00-6.2-2.493zm12.28-20a3.74 3.74 0 013.555 2.563l3.953 11.859c.062.187.062.383.093.578H21.25V0zM18.75 0v15H.102c.03-.195.03-.39.093-.578l3.953-11.86A3.742 3.742 0 017.703 0H18.75z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="t7jceta4jb" fill={fillColor}>
          <use xlinkHref="#ncn6txqoya" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#ncn6txqoya" />
        <g fill={fillColor} mask="url(#t7jceta4jb)">
          <path d="M-24-24h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
};

export default StockTurnIcon;
