import * as React from 'react';
import IIconProp from '../iconInterface';

const PaperPlanIcon: React.VFC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg
    className={className}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5954 0.126214L0.487941 10.5726C-0.219166 10.9789 -0.129313 11.9634 0.573888 12.2603L4.72668 14.0027L15.9505 4.11102C16.1654 3.91959 16.4701 4.21259 16.2865 4.43527L6.87534 15.9013V19.0462C6.87534 19.9682 7.98874 20.3315 8.53568 19.6635L11.0164 16.6436L15.8841 18.6829C16.4389 18.9173 17.0717 18.5696 17.1733 17.9719L19.9861 1.09507C20.1189 0.305921 19.2712 -0.264453 18.5954 0.126214Z"
      fill={fillColor}
    />
    <mask
      id="mask0_21_487"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="21"
    >
      <path
        d="M18.5954 0.126214L0.487941 10.5726C-0.219166 10.9789 -0.129313 11.9634 0.573888 12.2603L4.72668 14.0027L15.9505 4.11102C16.1654 3.91959 16.4701 4.21259 16.2865 4.43527L6.87534 15.9013V19.0462C6.87534 19.9682 7.98874 20.3315 8.53568 19.6635L11.0164 16.6436L15.8841 18.6829C16.4389 18.9173 17.0717 18.5696 17.1733 17.9719L19.9861 1.09507C20.1189 0.305921 19.2712 -0.264453 18.5954 0.126214Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_21_487)">
      <rect x="-12" y="-12" width="44" height="44" fill={fillColor} />
    </g>
  </svg>
);

export { PaperPlanIcon };
