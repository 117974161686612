import IIconProp from '../../../../iconInterface';

const RPlusIcon: React.FC<IIconProp> = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}) => (
  <svg viewBox="0 0 800 800" className={className}>
    <defs>
      <path
        id="RPlusIcon-a-alskdhfa"
        d="m425.03 426.36c49.31-31.56 73.34-72.46 72.15-122.65-1.19-28.64-11.51-54.89-30.98-78.83-25.67-31.29-59.76-50.02-102.29-56.21v-.44c-10.36-1.46-21.25-2.21-32.76-2.21-45.46.58-89.59 11.82-132.39 33.64-47.8 24.83-80.87 57.01-99.15 96.54-9.74 20.67-9.87 38.82-.44 54.44 11.82 19.21 34.39 30.54 67.72 34.08.89-9.43 2.66-19.34 5.31-29.66 10.62-37.49 31.29-70.38 61.97-98.75 39.26-36.3 82.82-52.23 130.62-47.81 9.74 2.97 18.46 6.51 26.12 10.62 30.1 15.93 42.05 39.7 35.85 71.26-5 27.44-22.13 53.43-51.35 77.9-33.64 28.64-70.69 44.44-111.15 47.36 21.25-44 46.21-93.26 74.81-147.89-35.72-33.33-62.72-39.39-81.05-18.15-69.89 133.25-122.74 250.1-158.42 350.76 0 4.12-.35 7.75-1.11 10.84-.75 3.1.53 5.84 3.76 8.19s5.67 4.34 7.3 5.98c1.64 1.64 4.96 2.97 9.96 3.98 5 1.02 8.85 1.86 11.51 2.43 2.66.58 6.73 1.02 12.17 1.33s9.43.44 11.95.44h10.4c2.97 0 5.18.13 6.64.44 14.16-39.84 31.29-82.82 51.35-128.85.31.31.75.75 1.33 1.33 75.87 59.62 142.57 85.03 200.12 76.13 12.7-2.35 23.46-8.99 32.31-19.92 8.85-12.08 10.76-29.35 5.75-51.79-2.97-13.85-6.2-21.56-9.74-23.02-6.2 3.85-13.15 6.95-20.8 9.3-38.38 10.62-90.48 2.08-156.3-25.67 2.08-.31 4.43-.44 7.08-.44 54.62-3.85 101.85-18.72 141.69-44.71h.06z"
      />

      <clipPath id="RPlusIcon-b-ldhadhad">
        <use xlinkHref="#RPlusIcon-a-alskdhfa" />
      </clipPath>
    </defs>

    <g fill={fillColor}>
      <path d="m742.61 372.09h-76.62v-76.63c0-5.08-4.12-9.2-9.2-9.2h-44.23c-5.08 0-9.2 4.12-9.2 9.2v76.63h-76.62c-5.08 0-9.2 4.12-9.2 9.2v44.23c0 5.08 4.12 9.2 9.2 9.2h76.62v76.63c0 5.08 4.12 9.2 9.2 9.2h44.23c5.08 0 9.2-4.12 9.2-9.2v-76.63h76.62c5.08 0 9.2-4.12 9.2-9.2v-44.23c0-5.08-4.12-9.2-9.2-9.2z" />

      <use xlinkHref="#RPlusIcon-a-alskdhfa" />

      <path
        clipPath="url(#RPlusIcon-b-ldhadhad)"
        d="m83.89 160.16h420.33v479.69h-420.33z"
      />
    </g>
  </svg>
);

export { RPlusIcon };
