// *******************************************************
// MinusIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../iconInterface';
export type IMinusIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const MinusIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IMinusIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
      className={className}
    >
      <defs>
        <path
          id="MinusIcona"
          d="M12.142.465H.258A.259.259 0 000 .723v1.034c0 .142.116.258.258.258h11.884a.259.259 0 00.258-.258V.723a.259.259 0 00-.258-.258z"
        />
      </defs>
      <g transform="translate(1.8 6.76)" fill="none" fillRule="evenodd">
        <mask id="MinusIconb" fill={fillColor}>
          <use xlinkHref="#MinusIcona" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#MinusIcona" />
        <g fill={fillColor} mask="url(#MinusIconb)">
          <path d="M-7.44-12.4h27.28v27.28H-7.44z" />
        </g>
      </g>
    </svg>
  );
};

export default MinusIcon;
