// *******************************************************
// BasketProfitIcon
// -------------------------------------------------------
// Component Description
// A basket icon with upword trend icon inside
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const BasketProfitIcon = ({
  className = 'h-full w-auto',
  fillColor = '#F28A14',
}: IIconProp): ReactElement => {
  return (
    <svg className={className} viewBox="0 0 576 512">
      <g>
        <path
          fill={fillColor}
          d="M552,187.1h-67.3L377.9,28.9c-10.4-15.4-30.4-18.8-44.7-7.6c-14.4,11.1-17.5,32.7-7.1,48.1l79.4,117.6h-235l79.4-117.6
 c10.4-15.4,7.2-36.9-7.1-48.1c-14.2-11.2-34.3-7.9-44.7,7.6L91.4,187.1H24c-13.2,0-24,11.5-24,25.9v17.2C0,244.5,10.8,256,24,256
 H32L58.2,453c3.3,25.5,23.6,44.3,47.5,44.3h364.7c24,0,44.1-18.9,47.6-44.3L544.1,256h8c13.3,0,24-11.5,24-25.9V213
 C576,198.6,565.3,187.1,552,187.1z M287.4,236.6c38.4,0,71.8,21.5,88.7,53.1c7.7,14.2,12,30.4,12,47.6s-4.3,33.3-12,47.5
 c-16.9,31.6-50.3,53.1-88.7,53.1c-37.3,0-69.9-20.4-87.3-50.6c-8.4-14.7-13.3-31.8-13.3-50c0-18.1,4.9-35.3,13.3-50.1
 C217.4,257,250,236.6,287.4,236.6z M176,428.5c-13.3,0-24-11.7-24-25.9V281.9c0-14.4,10.7-25.9,24-25.9c9.7,0,18,6.2,21.9,15.1
 c-13.8,18.6-21.9,41.4-21.9,66.1c0,25.9,8.8,49.7,23.8,68.6C198.3,418.6,188.2,428.5,176,428.5z M424.1,402.6
 c0,14.2-10.8,25.9-24.1,25.9c-12.6,0-23-10.6-23.8-24c14.2-18.7,22.7-42,22.7-67.2c0-24.3-7.8-46.6-21-65
 c3.6-9.6,12.1-16.3,22.2-16.3c13.3,0,24.1,11.5,24.1,25.9V402.6z"
        />
        <path
          fill={fillColor}
          d="M267.5,371.9c1.7,1.7,4.2,2.8,6.8,2.8s5.1-1.2,6.8-3.1l36.9-42.1l12.7,11.3c4,3.5,10.5,1.4,11.7-4l10.5-51.7
 c1.2-5.4-4-9.7-9.1-8l-50,17c-5.1,1.7-6.6,8.2-2.3,12l12.7,11.3l-30.6,34.1l-20.7-20.7c-1.9-1.9-4.5-2.8-7.1-2.8
 c-2.8,0.3-5.1,1.4-6.8,3.5l-33.2,42.3c5.1,3.7,9.7,8.2,13.6,13.1l27.8-35.2L267.5,371.9z"
        />
      </g>
    </svg>
  );
};

export default BasketProfitIcon;
