// *******************************************************
// MeatIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
//
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React, { ReactElement } from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';

// *******************************************
// Main Component
// -------------------------------------------
export const MeatIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IIconProp): ReactElement => {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Steak</title>
      <defs>
        <path
          d="M12.8074383,3.33330362 C11.8591828,3.33330362 11.0057182,4.15621295 10.6331521,5.42911827 C10.4164873,6.17077832 9.70434092,9.11693261 4.62070569,11.3318434 C-0.198695792,13.4273108 10.3126688,17.2154715 15.7754674,10.9908742 C17.1021916,9.47942938 16.9143461,6.61591324 15.3737348,4.85933168 C14.5091592,3.87427102 13.597709,3.33330362 12.8074383,3.33330362 Z M13.3334752,8.88533745 C12.719831,8.88533745 12.222374,8.3878805 12.222374,7.77423625 C12.222374,7.160592 12.719831,6.66313504 13.3334752,6.66313504 C13.9471195,6.66313504 14.4445764,7.160592 14.4445764,7.77423625 C14.4445764,8.3878805 13.9471195,8.88533745 13.3334752,8.88533745 Z M17.879268,2.66143461 C16.2473381,0.802423402 14.4542986,0 12.8074383,0 C10.3567657,0 8.22935407,1.77672027 7.43422227,4.49232106 C5.72937636,9.3509583 0.0478297883,7.3721565 0.000260767967,12.2092662 C-0.0410583081,16.431798 4.83598155,17.9248402 8.88907041,17.7647722 C11.9369599,17.6467177 15.3931791,16.4769364 18.2810006,13.1870352 C20.7216038,10.4078933 20.5417443,5.69439368 17.879268,2.66143461 Z M16.6108766,11.7238538 C13.9668029,14.7359797 10.9053719,15.4654871 8.80469616,15.5467364 C7.49741615,15.5946526 4.23807021,15.3203495 2.85891584,13.9280008 C2.77245828,13.8408488 0.795045349,11.7835755 4.17834852,10.3110192 C6.66582634,9.22804273 8.77205757,7.83743013 9.56823102,5.11662105 C10.0814209,3.35760896 11.3543262,2.22220241 12.8074383,2.22220241 C13.9386782,2.22220241 15.1147094,2.88191875 16.2101857,4.12774098 C18.1250992,6.30966597 18.3091253,9.7884544 16.6108766,11.7235066 L16.6108766,11.7238538 Z"
          id="path-1-meat-icon-dasda"
        ></path>
      </defs>
      <g
        id="00-Micronaires/Icons/Steak"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Colours/Black-#000000-meat-icon"
          transform="translate(2.000000, 3.000000)"
        >
          <mask id="mask-2-meat-icon-mpo" fill="white">
            <use xlinkHref="#path-1-meat-icon-dasda"></use>
          </mask>
          <use
            id="Mask-meat-icon-lsdgah"
            fill={fillColor}
            fillRule="nonzero"
            xlinkHref="#path-1-meat-icon-dasda"
          ></use>
          <g id="Group-meat-icon-lsdgah" mask="url(#mask-2-meat-icon-mpo)">
            <g transform="translate(-12.000000, -13.000000)">
              <rect
                id="Rectangle-meat-icon-lsdgah"
                fill={fillColor}
                x="0"
                y="0"
                className={className}
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MeatIcon;
