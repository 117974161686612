import React, { FunctionComponent } from 'react';

interface IMeritIconProps {
  importance?: string;
  className?: string;
  circleFillColor?: string;
  isWhite?: boolean;
}

export const MeritScoreIcon: FunctionComponent<
  React.PropsWithChildren<IMeritIconProps>
> = ({
  importance,
  className = 'h-15 w-auto',
  circleFillColor = '#fff',
  isWhite = false,
}) => {
  let diamondColor = '#000';

  switch (importance) {
    case 'critical':
      diamondColor = '#f28a14';
      break;
    case 'high':
      diamondColor = '#5a3b8f';
      break;
    case 'medium':
      diamondColor = '#23b0e7';
      break;
    case 'low':
      diamondColor = '#889aa4';
      break;
    case 'trivial':
      diamondColor = '#e7ebed';
      break;

    default:
      diamondColor = '#000';
      break;
  }

  if (isWhite) {
    diamondColor = '#fff';
  }

  return (
    <svg
      className={className}
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="MeritScorepath-1" cx="30" cy="30" r="30"></circle>
        <path
          d="M15.4995183,31 L5.14865682,20.6496654 L5.149,20.649 L7.08766379e-13,15.5002635 L5.20167774,10.2985857 L10.298,5.202 L10.2978405,5.20167774 L15.4995183,5.59552404e-14 L20.701196,5.20167774 L20.701,5.202 L25.798,10.299 L25.7983223,10.2985857 L31,15.5002635 L15.4995183,31 Z M15.5,10.404 L10.4033555,15.5002635 L15.499,20.596 L20.5966445,15.5002635 L15.5,10.404 Z"
          id="MeritScorepath-3"
        ></path>
      </defs>
      <g
        id="app-icon-MeritScore"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Components/icons/circle-coloured-Copy-12">
          <g id="MeritScoreColours/Pear-#CAD526">
            <mask id="MeritScoremask-2" fill="white">
              <use xlinkHref="#MeritScorepath-1"></use>
            </mask>
            <circle stroke="#EEEEEE" cx="30" cy="30" r="29.5"></circle>
            <g
              id="MeritScoreGroup"
              mask="url(#MeritScoremask-2)"
              fill={circleFillColor}
            >
              <g id="MeritScoreColours/Pear-#CAD526">
                <rect
                  id="MeritScoreRectangle"
                  x="0"
                  y="0"
                  width="60"
                  height="60"
                ></rect>
              </g>
            </g>
          </g>
          <g id="MeritScoreGroup" transform="translate(11.400000, 11.400000)">
            <g
              // id=`MeritScoreColours/Black-${circleFillColor}`
              transform="translate(3.100000, 3.100000)"
            >
              <mask id="MeritScoremask-4" fill="white">
                <use xlinkHref="#MeritScorepath-3"></use>
              </mask>
              <use
                id="MeritScoreMask"
                fill={diamondColor}
                fillRule="nonzero"
                xlinkHref="#MeritScorepath-3"
              ></use>
              <g id="MeritScoreGroup" mask="url(#MeritScoremask-4)">
                <g
                  transform="translate(-18.600000, -18.600000)"
                  // id="MeritScoreColours/Black-#000000"
                >
                  <rect
                    id="MeritScoreRectangle"
                    fill={diamondColor}
                    x="0"
                    y="0"
                    width="68.2"
                    height="68.2"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MeritScoreIcon;

// TODO trying with IDs, not working, abandonned
// import React, { FunctionComponent } from 'react';

// interface IMeritIconProps {
//   idPrefix: string;
//   importance?: string;
//   className?: string;
//   circleFillColor?: string;
//   isWhite?: boolean;
// }

// export const MeritScoreIcon: FunctionComponent<IMeritIconProps> = ({
//   importance,
//   className = 'h-15 w-auto',
//   idPrefix = 'a',
//   circleFillColor = '#fff',
//   isWhite = false,
// }) => {
//   let diamondColor = '#000';
//   switch (importance) {
//     case 'critical':
//       diamondColor = '#f28a14';
//       break;
//     case 'high':
//       diamondColor = '#5a3b8f';
//       break;
//     case 'medium':
//       diamondColor = '#23b0e7';
//       break;
//     case 'low':
//       diamondColor = '#889aa4';
//       break;
//     case 'trivial':
//       diamondColor = '#e7ebed';
//       break;

//     default:
//       diamondColor = '#000';
//       break;
//   }

//   if (isWhite) {
//     diamondColor = '#fff';
//   }

//   return (
//     <svg
//       className={className}
//       viewBox="0 0 60 60"
//       version="1.1"
//       xmlns="http://www.w3.org/2000/svg"
//       xmlnsXlink="http://www.w3.org/1999/xlink"
//     >
//       <defs>
//         <circle
//           id={`${idPrefix}-MeritScorepath-1`}
//           cx="30"
//           cy="30"
//           r="30"
//         ></circle>
//         <path
//           d="M15.4995183,31 L5.14865682,20.6496654 L5.149,20.649 L7.08766379e-13,15.5002635 L5.20167774,10.2985857 L10.298,5.202 L10.2978405,5.20167774 L15.4995183,5.59552404e-14 L20.701196,5.20167774 L20.701,5.202 L25.798,10.299 L25.7983223,10.2985857 L31,15.5002635 L15.4995183,31 Z M15.5,10.404 L10.4033555,15.5002635 L15.499,20.596 L20.5966445,15.5002635 L15.5,10.404 Z"
//           id="MeritScorepath-3"
//         ></path>
//       </defs>
//       <g
//         id={`${idPrefix}-app-icon-MeritScore`}
//         stroke="none"
//         strokeWidth="1"
//         fill="none"
//         fillRule="evenodd"
//       >
//         <g id={`${idPrefix}-Components/icons/circle-coloured-Copy-12`}>
//           <g id={`${idPrefix}-MeritScoreColours/Pear-#CAD526`}>
//             <mask id={`${idPrefix}-MeritScoremask-2`} fill="white">
//               <use xlinkHref={`#${idPrefix}-MeritScorepath-1`}></use>
//             </mask>
//             <circle stroke="#EEEEEE" cx="30" cy="30" r="29.5"></circle>
//             <g
//               id={`${idPrefix}-MeritScoreGroup`}
//               mask={`url(#${idPrefix}-MeritScoremask-2)`}
//               fill={circleFillColor}
//             >
//               <g id={`${idPrefix}-MeritScoreColours/Pear-#CAD526`}>
//                 <rect
//                   id={`${idPrefix}-MeritScoreRectangle`}
//                   x="0"
//                   y="0"
//                   width="60"
//                   height="60"
//                 ></rect>
//               </g>
//             </g>
//           </g>
//           <g
//             id={`${idPrefix}-MeritScoreGroup`}
//             transform="translate(11.400000, 11.400000)"
//           >
//             <g
//               id={`${idPrefix}-MeritScoreColours/Black-#000000`}
//               transform="translate(3.100000, 3.100000)"
//             >
//               <mask id={`${idPrefix}-MeritScoremask-4`} fill="white">
//                 <use xlinkHref={`#${idPrefix}-MeritScorepath-3`}></use>
//               </mask>
//               <use
//                 id={`${idPrefix}-MeritScoreMask`}
//                 fill={diamondColor}
//                 fillRule="nonzero"
//                 xlinkHref={`#${idPrefix}-MeritScorepath-3`}
//               ></use>
//               <g
//                 id={`${idPrefix}-MeritScoreGroup`}
//                 mask={`url(#${idPrefix}-MeritScoremask-4)`}
//               >
//                 <g
//                   transform="translate(-18.600000, -18.600000)"
//                   id={`${idPrefix}-MeritScoreColours/Black-#000000`}
//                 >
//                   <rect
//                     id={`${idPrefix}-MeritScoreRectangle`}
//                     fill={diamondColor}
//                     x="0"
//                     y="0"
//                     width="68.2"
//                     height="68.2"
//                   ></rect>
//                 </g>
//               </g>
//             </g>
//           </g>
//         </g>
//       </g>
//     </svg>
//   );
// };

// export default MeritScoreIcon;
