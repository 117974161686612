// *******************************************************
// StockProfitIcon
// -------------------------------------------------------
// Component Description
//
// Link to icon:
// https://app.zeplin.io/project/5e350b21dec57db42a09b613/screen/604a01c40b5e799802c80e2a
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import React from 'react';

// *******************************************
// Interface Imports
// -------------------------------------------
import IIconProp from '../../../iconInterface';
export type IStockProfitIconProps = IIconProp;

// *******************************************
// Main Component
// -------------------------------------------
export const StockProfitIcon = ({
  className = 'h-full w-auto',
  fillColor = '#000',
}: IStockProfitIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 48 48"
      className={className}
    >
      <defs>
        <path
          fill={fillColor}
          id="d09bxd9l9a"
          d="M39.964 11.321l-5.026-9.366A2.863 2.863 0 0032.222 0H21.43v11.429h18.455c.036-.045.045-.063.08-.108zm-21.393.108V0H7.777a2.863 2.863 0 00-2.715 1.955L.037 11.321c.035.045.044.063.08.108h18.455zM0 14.286v22.857A2.854 2.854 0 002.857 40h34.286A2.854 2.854 0 0040 37.143V14.286H0zm21.429 19.99v1.438a.717.717 0 01-.715.715h-1.428a.717.717 0 01-.715-.715V34.26a5.152 5.152 0 01-2.803-1.009.714.714 0 01-.054-1.08l1.054-1a.726.726 0 01.902-.063c.348.214.74.33 1.142.33h2.51c.58 0 1.053-.526 1.053-1.178 0-.536-.321-1-.786-1.134l-4.018-1.205c-1.66-.5-2.821-2.09-2.821-3.875 0-2.188 1.696-3.965 3.813-4.027V18.57c0-.392.32-.714.714-.714h1.428c.393 0 .715.322.715.714v1.456a5.144 5.144 0 012.803 1.009c.348.259.366.785.054 1.08l-1.045 1.009a.727.727 0 01-.902.063 2.168 2.168 0 00-1.142-.33h-2.51c-.58 0-1.053.526-1.053 1.178 0 .535.321 1 .786 1.134l4.018 1.205c1.66.5 2.821 2.09 2.821 3.875-.009 2.188-1.714 3.973-3.821 4.027z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="nwfiqtksfb" fill={fillColor}>
          <use xlinkHref="#d09bxd9l9a" />
        </mask>
        <use fill={fillColor} fillRule="nonzero" xlinkHref="#d09bxd9l9a" />
        <g fill={fillColor} mask="url(#nwfiqtksfb)">
          <path d="M-24-24h88v88h-88z" />
        </g>
      </g>
    </svg>
  );
};

export default StockProfitIcon;
